import { FaTimes, FaCloudUploadAlt } from "react-icons/fa";
import { useEffect, useState, useRef } from "react";
import pathconfig from '../../pathconfig.json';
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";
import moment from 'moment';
import { MdDownload } from "react-icons/md";
import { toastError, toastSuccess, toastWarning } from '../toaster';
import NodataFound from "../nodata";
import packjson from '../../../package.json';

const Supplementary = (props) => {
    const [fileData, setFileData] = useState([]);
    const location = useLocation();
    const fileInputRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!props.fileReload) {
            const docid = location.search.split('?')[1].split('=')[1];
            getFiles(docid);
        }
    }, [props.fileReload]);

    const getFiles = (value) => {
        let url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].retrievefiles}`;
        let GetDetailDataString = localStorage.getItem(`${value}_init-getdetails`);
        let GetDetailData = JSON.parse(GetDetailDataString);
        var data = {
            InputPath: GetDetailData.Articledetails.ArtResourcePath
        }
        axios.post(url, data)
            .then((response) => {
                setFileData(response.data)
            }).catch((error) => {
                console.log(error)
                const docid = location.search.split('?')[1].split('=')[1];
                navigate(`/notification?docid=${docid}`, { state: { status: 'error', user: '' } })

            })
    }

    const fileDownload = async (fileType, path) => {
        let fileExtension = fileType.split('.')[1];
        let mimeType;
        if (fileExtension === 'doc') {
            mimeType = 'application/msword';
        } else if (fileExtension === 'docx') {
            mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        } else if (fileExtension === 'xls') {
            mimeType = 'application/vnd.ms-excel';
        } else if (fileExtension === 'xlsx') {
            mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        } else if (fileExtension === 'ppt') {
            mimeType = 'vnd.ms-powerpoint';
        } else if (fileExtension === 'pptx') {
            mimeType = 'vnd.openxmlformats-officedocument.presentationml.presentation';
        } else if (fileExtension === '7z') {
            mimeType = 'x-7z-compressed';
        } else if (fileExtension === 'swf') {
            mimeType = 'x-shockwave-flash';
        } else {
            mimeType = `application/${fileExtension}`;
        }
        let urlPath = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].download}`;

        const response = await axios.post(urlPath, {
            blobUrl: path,
            type: mimeType
        }, {
            responseType: 'blob',// Important               
        });
        const url = window.URL.createObjectURL(new Blob([response.data], { type: mimeType }));
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${fileType}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        toastSuccess(`Supplementary file has been successfully downloaded.`);
    }

    const handleFileupload = (e) => {
        const file = e.target.files[0].name.split('.')[1];
        try {
            const docId = location.search.split('?')[1].split('=')[1];
            let GetDetailDataString = localStorage.getItem(`${docId}_init-getdetails`);
            let GetDetailData = JSON.parse(GetDetailDataString);
            var formdata = new FormData();
            let filterFiles = fileData.filter((item) => item.path.split('/').pop() === e.target.files[0].name)
            console.log(filterFiles, 'filterFiles')
            if (file === 'tex') {
                formdata.append("files", e.target.files[0]);
                formdata.append("path", GetDetailData.Articledetails.ArtResourcePath);
                const url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].supplymentAttachment}`;
                axios.post(url, formdata, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                }
                ).then((response) => {
                    if (response.data.success === true) {
                        toastSuccess(`${response.data.message}`)
                    }
                }
                ).catch(error => {
                    console.log(error)
                    const docid = location.search.split('?')[1].split('=')[1];
                    navigate(`/notification?docid=${docid}`, { state: { status: 'error', user: '' } })
                })
            } else {
                if (filterFiles.length) {
                    formdata.append("files", e.target.files[0]);
                    formdata.append("path", GetDetailData.Articledetails.ArtResourcePath);
                    const url = pathconfig.supplymentAttachment;
                    axios.post(url, formdata, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        }
                    }
                    ).then((response) => {
                        if (response.data.success === true) {
                            toastSuccess(`${response.data.message}`)
                        }
                    }
                    ).catch(error => {
                        console.log(error)
                        const docid = location.search.split('?')[1].split('=')[1];
                        navigate(`/notification?docid=${docid}`, { state: { status: 'error', user: '' } })
                    })
                } else {
                    toastError('Faild to upload, File name does not exist.');
                }
            }

        } catch (error) {
            console.log(error)
        }
        e.target.value = null;
    }

    return (
        <div className="supplementary-area">
            <div className="slider-header">
                <h5>Supplementary Files</h5>
                <div style={{ width: '20px' }}>
                    <button className="close-btn" onClick={props.close} title="Close">
                        <FaTimes />
                    </button>
                </div>
            </div>
            <div className="supplementary-list">
                <div className="upload-btn-wrapper">
                    <FaCloudUploadAlt />
                    <p>Choose file or drag here</p>
                    <input type="file" accept=".ini,.tex" onChange={(e) => handleFileupload(e)} ref={fileInputRef} />
                </div>
                {fileData.length !== 0 ? fileData.map((item, index) =>
                    <div className="supplementary-list-group" key={index}>
                        <button className="download-btn" title="Download" onClick={() => fileDownload(item.path.split('/').pop(), item.path)}><MdDownload /></button>
                        <p title={item.path.split('/').pop()}>{item.path.split('/').pop()}</p>
                        <span>Date: {moment(item.createdOn).format("DD MMMM YYYY - HH:mm:ss")}</span>
                    </div>
                ) : <NodataFound />}
            </div>
        </div>
    )
}
export default Supplementary;