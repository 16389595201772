import { useState, useEffect, useRef } from "react";
import { FaBold, FaItalic, FaUnderline, FaTimes, FaReply, FaEdit, FaSave, FaSuperscript, FaSubscript, FaPaperPlane, FaPaperclip, FaRegTrashAlt } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { dispatchFoucs, dispatchAQchange, dispatchAQdelete, dispatchAuthorQuery } from "../../redux/action";
import { useLocation, useNavigate } from "react-router-dom";
import { toastError, toastSuccess } from '../toaster';
import pathconfig from '../../pathconfig.json';
import axios from "axios";
import NodataFound from "../nodata";
import packjson from '../../../package.json';

const QueryTab = (props) => {
    const [query, setQuery] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    const [editedQuery, setEditedQuery] = useState("");
    const [replyIndex, setReplyIndex] = useState(null);
    const [replyContent, setReplyContent] = useState("");
    const [fileAttachment, setFileAttachment] = useState([]);
    const [fileAllData, setFileAllData] = useState([]);
    const [queryFile, setQueryFile] = useState();
    const [queryStatus, setQueryStatus] = useState(false);
    const [queryData, setQueryData] = useState({})
    const location = useLocation();
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const [loader, setLoader] = useState(false);
    const [render, setRender] = useState(false);
    const [answerCount, setAnswerCount] = useState(0)
    // const location = useLocation();

    useEffect(() => {
        if (props.isAuthorQuery.length !== undefined) { //if condition added
            const sortedData = props.isAuthorQuery.sort((a, b) => a.label.localeCompare(b.label));
            sortedData.map((item) => item.ActivityID === undefined ? props.isDocvalue.ActivityID : item.ActivityID)
            setQuery(sortedData);
        } else {
            setQuery([])
        }
    }, [props.isAuthorQuery]); // Change dependency to props.isAuthorQuery for clarity

    // useEffect(() => {
    //     if (props.isDocvalue.state.doc !== null) {
    //         AQFinder(props.isDocvalue.state.doc)
    //     }
    // }, [props.isDocvalue]); // Change dependency to props.isDocvalue for clarity

    useEffect(() => {
        if (query.length > 0) {
            props.dispatchAuthorQuery(query);
            let answerFilter = query.filter((a) => a?.answerCheck === 'yes' || a?.repliesMark === true)
            setAnswerCount(answerFilter.length)
        }
    }, [query])

    // function isYesNoQuestion(sentence) {
    //     // Define regex patterns for yes/no questions
    //     const yesNoPatterns = [/^(is|are|do|does|did|can|could|will|would|should|has|have|am|was|were)\b/i];
    //     // Check if the sentence matches any of the yes/no patterns
    //     return yesNoPatterns.some(pattern => pattern.test(sentence));
    // }

    useEffect(() => {
        getDetails()
    }, [])

    const queryScroll = (number) => {
        props.dispatchFoucs(number);
    };


    // const AQFinder = async (value) => {
    //     const doc = value;
    //     const lineCount = doc.lines;
    //     const extractedQueries = [];
    //     const queryRegex = /\\AQ(?:\[[^\]]*\])?\{([^{}]*)\}\{((?:[^{}]|{(?:[^{}]|{[^{}]*})*})*)\}(?:{})?/gis;
    //     for (let i = 1; i <= lineCount; i++) {
    //         const lineContent = doc.line(i).text;
    //         let match;
    //         while ((match = queryRegex.exec(lineContent)) !== null) {
    //             extractedQueries.push({
    //                 linenumber: i,
    //                 query: match[2],
    //                 label:match[1],
    //                 wholematch: match[0],
    //                 attachment: []
    //             });
    //         }
    //     }
    //     if (Object.keys(props.isAuthorQuery).length !== 0) {
    //         if (extractedQueries.length === props.isAuthorQuery.length) {
    //             const extractedQueriesList = props.isAuthorQuery.map((itemA) => {
    //                 const a = extractedQueries.find((itemB) => itemB.label === itemA.label);
    //                 return a ? { ...itemA, linenumber: a.linenumber, ...a  } : { ...itemA }
    //             }
    //             )
    //             let checkQuery = extractedQueriesList.map((item) => {
    //                 return {
    //                     ...item,
    //                     aqCheck: isYesNoQuestion(item.query)
    //                 }
    //             }
    //             )
    //             setQuery(checkQuery)
    //         }else{
    //             let checkQuery = extractedQueries.map((item) => {
    //                 return {
    //                     ...item,
    //                     aqCheck: isYesNoQuestion(item.query)
    //                 }
    //             })
    //             setQuery(checkQuery)
    //         }

    //     } else {
    //         let checkQuery = extractedQueries.map((item) => {
    //             return {
    //                 ...item,
    //                 aqCheck: isYesNoQuestion(item.query)
    //             }
    //         })
    //         setQuery(checkQuery)
    //     }
    // };

    const handleDelete = (lineNumber, wholeMatch) => {
        const querydelete = { linenumber: lineNumber, Content: wholeMatch };
        props.dispatchAQdelete(querydelete);
        const updatedQuery = query.filter((item, index) => item.linenumber !== lineNumber);
        setQuery(updatedQuery); // Update the state with the deleted query
    };

    const handleEdit = (index, currentQuery) => {
        setEditingIndex(index);
        setEditedQuery(currentQuery);
        setReplyIndex(null); // Cancel reply mode if editing
    };

    const handleSave = (lineNumber, wholeMatch, editedContent) => {
        const querychange = { linenumber: lineNumber, oldcontent: wholeMatch, changecontent: editedContent };
        props.dispatchAQchange(querychange);
        const updatedQuery = query.map((item, index) =>
            index === editingIndex ? { ...item, query: editedContent, wholematch: wholeMatch } : item
        );
        setQuery(updatedQuery); // Update the state with the new query
        setEditingIndex(null); // Reset editing state
        setEditedQuery(""); // Clear edited query state
    };

    const handleCancel = () => {
        setEditingIndex(null);
        setEditedQuery("");
        setReplyIndex(null); // Reset reply state
        setReplyContent(""); // Clear reply content state
        setFileAttachment([]); // Clear file attachments
    };

    const handleReply = (index) => {
        setReplyIndex(index);
        setEditingIndex(null); // Cancel editing mode if replying
        setFileAttachment([]); // Clear previous file attachments
        setQueryFile(); // Reset query file
    };

    const handleReplySave = (index) => {
        const replyContentHtml = document.getElementById("TypeContent").innerHTML;
        setTimeout(() => {
            const updatedQuery = query.map((item, idx) =>
                idx === index ? { 
                    ...item,
                    replies: [...(item.replies || []), replyContentHtml],
                    attachment: [...(item.attachment || []), queryFile], 
                    repliesMark: true,
                    // answerCheck: item.aqCheck ? 'no' : undefined // Maintain consistency with radio state
                 } : item
            );
            setQuery(updatedQuery); // Update the state with the new reply
        }, 1000);
        setReplyIndex(null); // Reset reply state
        setReplyContent(""); // Clear reply content state
        setFileAttachment([]); // Reset file attachments for the next reply
        setQueryFile(); // Clear the query file for the next reply
    };


    const applyStyle = (command) => {
        const currentSelection = window.getSelection();
        if (currentSelection.rangeCount > 0) {
            const range = currentSelection.getRangeAt(0);
            const parentElement = range.commonAncestorContainer.parentNode;
            if (command === 'superscript' || command === 'subscript') {
                if (parentElement.tagName.toLowerCase() === (command === 'superscript' ? 'sup' : 'sub')) {
                    const grandparent = parentElement.parentNode;
                    while (parentElement.firstChild) {
                        grandparent.insertBefore(parentElement.firstChild, parentElement);
                    }
                    grandparent.removeChild(parentElement);
                } else {
                    document.execCommand(command, false, null);
                }
            } else {
                document.execCommand(command, false, null);
            }
        }
    };

    const handleUpload = (e, index) => {
        let dataCheck = fileAllData.filter((item) => item.Filename === e.target.files[0].name);
        if (dataCheck.length === 0) {
            let file = e.target.files[0];
            const maxFileSize = 40 * 1024 * 1024;
            if (file.size > maxFileSize) {
                toastError('The file size should not exceed more than 40 MB')
                e.target.value = null;
                return;
            }
            handleFileupload(e.target.files[0], index)
        } else {
            toastError(`${e.target.files[0].name} filename already uploaded. Please rename and retry.`)
        }
        e.target.value = null;
    }

    const handleFileupload = (filename, index) => {
        setLoader(true)
        try {
            const docId = location.search.split('?')[1].split('=')[1];
            let GetDetailDataString = localStorage.getItem(`${docId}_init-getdetails`);
            let GetDetailData = JSON.parse(GetDetailDataString);
            var formdata = new FormData();
            formdata.append("jobGUID", GetDetailData.Articledetails.ArticleGUID);
            formdata.append("ActivityID", GetDetailData.ActivtyDetails.ActivityID);
            formdata.append("docid", docId);
            formdata.append("type", "commentAttachment");
            formdata.append("files", filename);
            const url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].upload_attachment}`;
            axios.post(url, formdata, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
            ).then((response) => {
                if (response.data.SuccessFiles.length !== 0) {
                    response.data.SuccessFiles.map((item) =>
                        toastSuccess(`${item.data[0].Filename} - ${item.message}`)
                    )
                }
                if (response.data.FailedFiles.length !== 0) {
                    response.data.FailedFiles.map((item) =>
                        toastError(`${item.data[0].Filename} - ${item.message}`)
                    )
                }
                if (response.data.success === true) {
                    getDetails(filename, index)
                }
            }
            ).catch(error => {
                console.log(error)
                const docid = location.search.split('?')[1].split('=')[1];
                navigate(`/notification?docid=${docid}`, { state: { status: 'error', user: '' } })

            })
        } catch (error) {
            console.log(error)
        }
    }

    const getDetails = (filename, index) => {
        props.getTrigger(false)
        const docId = location.search.split('?')[1].split('=')[1];
        let GetDetailDataString = localStorage.getItem(`${docId}_init-getdetails`);
        let GetDetailData = JSON.parse(GetDetailDataString);
        const getUrl = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].getAttachment}`;
        const body = {
            "Proof_Type": GetDetailData.jobdetails.ProofType,
            "JobGUID": GetDetailData.jobdetails.JobGUID,
            "docid": docId
        }
        axios.post(getUrl, body).then((response) => {
            let dataChange = response.data.data.map((item) => {
                return {
                    ...item,
                    filetype: item.Filename.split('.')[1]
                }
            }
            )
            let commentType = dataChange.filter((item) => item.AttachType === 'commentAttachment')
            if (filename) {
                let files = commentType.filter((item) => item.Filename === filename.name)
                setFileAttachment(files)
                if (index !== null && index !== undefined) {
                    let a = [...query]
                    a[index].attachment.push(files[0])
                    setQueryFile(a)
                }
                else {
                    setQueryFile(files[0])
                }
            } else {
                setFileAllData(commentType)
            }
            props.getTrigger(true)
            setLoader(false)
        })
            .catch((error) => {
                console.log(error)
                const docid = location.search.split('?')[1].split('=')[1];
                navigate(`/notification?docid=${docid}`, { state: { status: 'error', user: '' } })
            })
    }

    const deleteConfirm = (value, index, parentIndex) => {
        console.log(value, index, parentIndex, 'value, index, parentIndex')
        const docId = location.search.split('?')[1].split('=')[1];
        let GetDetailDataString = localStorage.getItem(`${docId}_init-getdetails`);
        let GetDetailData = JSON.parse(GetDetailDataString);
        let data = {
            "ArticleGUID": value.ArticleGUID,
            "AttachID": value.AttachID,
            "Proof_Type": GetDetailData.jobdetails.ProofType,
            "AttachFlag": value.AttachFlag,
            "docid": docId
        }
        const deleteUrl = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].deleteAttachment}`
        axios.post(deleteUrl, data).then((response) => {
            toastSuccess(`${response.data.data[0].Filename} - ${response.data.message}`)
            getDetails()
            setFileAttachment([])
            if (index !== null) {
                let FileDetails = [...query];
                FileDetails[parentIndex].attachment.splice(index, 1)
                setQuery(FileDetails);
            }
            setQueryFile()
        }).catch((error) => {
            console.log(error)
            const docid = location.search.split('?')[1].split('=')[1];
            navigate(`/notification?docid=${docid}`, { state: { status: 'error', user: '' } })
        })
    }

    const handleMark = (idvalue) => {
        if (query[idvalue].repliesMark !== undefined) {
            if (query[idvalue].repliesMark === true) {
                const updatedQuery = query.map((item, idx) =>
                    idx === idvalue ? { ...item, repliesMark: false } : item
                );
                setQuery(updatedQuery);
            } else {
                const updatedQuery = query.map((item, idx) =>
                    idx === idvalue ? { ...item, repliesMark: true } : item
                );
                setQuery(updatedQuery);
            }
        } else {
            const updatedQuery = query.map((item, idx) =>
                idx === idvalue ? { ...item, repliesMark: true } : item
            );
            setQuery(updatedQuery);
        }

    }

    const editReply = (id, replyId) => {
        setQueryData({ 'value': query[id].replies[replyId], 'id': replyId, 'parentID': id })
        setQueryStatus(true)
    }
    const handleReplySaved = (id, replyId) => {
        const replyContentHtml = document.getElementById("TypeContentEdit").innerHTML;
        let replyArray = [...query]
        replyArray[id].replies[replyId] = replyContentHtml;
        setQuery(replyArray)
        setQueryData({ 'value': '', 'id': '' })
        setQueryStatus(false)
    }

    const editClose = () => {
        setQueryData({ 'value': '', 'id': '' })
        setQueryStatus(false)
    }
    const replyDelete = (id, replyId) => {
        let replyArray = [...query]
        replyArray[id].replies.splice(replyId, 1);
        setQuery(replyArray)
        if (replyArray[id].replies.length === 0) {
            replyArray[id].repliesMark = false;
            replyArray[id].answerCheck = undefined; // Reset radio selection
            setQuery(replyArray)
        }

        // if (replyId === 0) {
        //     replyArray[id].repliesMark = false;
        //     setQuery(replyArray)
        // }
    }
    const handleRadio = (e, id) => {
        const radioValue = e.target.value;
        const updatedQuery = query.map((item, idx) =>
            idx === id ? {
                ...item,
                answerCheck: radioValue,
                repliesMark: radioValue === 'yes'? true : (item.replies?.length > 0),
                previousReply: radioValue === 'yes' ? item.replies : item.previousReply,
                replies: item.replies || [], // Preserve existing replies
                showReplyArea: radioValue === 'no'
            } : item
        );
    
        if (radioValue === 'no') {
            if (!query[id].replies || query[id].replies.length === 0) {
                setReplyIndex(id);
            } else {
                setQueryData({ 
                    'value': query[id].replies[0], 
                    'id': 0, 
                    'parentID': id 
                });
                setQueryStatus(true);
            }
        } else {
            setReplyIndex(null);
            setQueryStatus(false); // This will hide the reply area
            setQueryData({ 'value': '', 'id': '', 'parentID': '' }); // Clear query data
        }
        
        setQuery(updatedQuery);
        setRender(!render);
    };
    
    return (
        <div className="comment-area">
            <div className="slider-header">
                <h5>Author Query - ({answerCount}/{query.length})</h5>
                <div style={{ width: '20px' }}>
                    <button className="close-btn">
                        <FaTimes onClick={props.close} />
                    </button>
                </div>
            </div>
            <div className="comment-group author-query">
                {query.length === 0 ? <NodataFound /> : ''}
                {console.log(query, 'query')}
                {query.map((item, index) =>
                    <div className={item.repliesMark || item.answerCheck === 'yes'
                        ? "comment-inner replied" : "comment-inner "} key={index} onClick={() => queryScroll(item)}>
                        {/* <h5>{item.Role} <label>{item.Date}</label></h5> */}
                        {editingIndex === index ? (
                            <textarea
                                value={editedQuery}
                                onChange={(e) => setEditedQuery(e.target.value)}
                            />
                        ) : (
                            <p>A{item.label}: {item.query}</p>
                        )}
                        {item.replies && item.replies.map((reply, idx) =>
                            <>
                                {(idx !== queryData.id && queryData.parentID !== index && item.answerCheck !== 'yes') ?
                                    <div className="reply-group">
                                        <div key={idx} className="reply-content">
                                            {/* <p>Reply {idx + 1}:</p> */}
                                            <p>Reply :</p>
                                            <div dangerouslySetInnerHTML={{ __html: reply }}></div>
                                        </div>
                                        <div className="reply-footer">
                                            <button onClick={() => editReply(index, idx)} title="Edit" className="edit"><FaEdit /></button>
                                            <button onClick={() => replyDelete(index, idx)} title="Delete" className="detele"><FaRegTrashAlt /></button>
                                        </div>
                                    </div> : ''}
                                <>
                                    {queryStatus && (index === queryData.parentID && idx === queryData.id) ? <div className="reply-area">
                                        <div className="reply-btns">
                                            <div>
                                                <Button onClick={() => applyStyle('bold')}>
                                                    <FaBold />
                                                </Button>
                                                <Button onClick={() => applyStyle('italic')}>
                                                    <FaItalic />
                                                </Button>
                                                <Button onClick={() => applyStyle('underline')}>
                                                    <FaUnderline />
                                                </Button>
                                                <Button onClick={() => applyStyle('superscript')}>
                                                    <FaSuperscript />
                                                </Button>
                                                <Button onClick={() => applyStyle('subscript')}>
                                                    <FaSubscript />
                                                </Button>
                                                {/* {item.attachment.length !== 0 ? */}
                                                <Button className="attchcomment-btn">
                                                    <FaPaperclip />
                                                    <input type="file" onChange={(e) => handleUpload(e, index)} ref={fileInputRef}></input>
                                                </Button>
                                                {/* : ""} */}
                                            </div>
                                            <div>
                                                <Button onClick={editClose}><FaTimes /></Button>
                                            </div>
                                        </div>
                                        <div className="CommentContent">
                                            <div contentEditable="true" id="TypeContentEdit" className="CommentTextEntry UIFont Cmdtxtcontent">{queryData.value}</div>
                                        </div>
                                        {loader ? <div className='fileupload-loder'><span className="loader"></span></div> : ''}
                                        <div className="reply-footer">
                                            <Button className="save-btn" onClick={() => handleReplySaved(index, queryData.id)}><FaSave /> Save</Button>
                                            <Button className="cancel-btn" onClick={editClose}><FaTimes /> Cancel</Button>
                                        </div>
                                    </div> : ""}
                                </>
                            </>
                        )}
                        {item.attachment !== undefined ?
                            <div className='comment-attached' style={{ marginTop: '6px' }}>
                                {item.attachment.map((file, a) =>
                                    (file !== null && file !== undefined) ?
                                        <div className='comment-attached-list' key={a}>
                                            <p>{file?.Filename}</p>
                                            <label>size - {file?.Filesize}</label>
                                            <button onClick={() => deleteConfirm(file, a, index)}><FaRegTrashAlt /></button>
                                        </div> : ''
                                )}
                            </div> : ''}
                        {replyIndex === index && (
                            <div className="reply-area">
                                <div className="reply-btns">
                                    <div>
                                        <Button onClick={() => applyStyle('bold')}>
                                            <FaBold />
                                        </Button>
                                        <Button onClick={() => applyStyle('italic')}>
                                            <FaItalic />
                                        </Button>
                                        <Button onClick={() => applyStyle('underline')}>
                                            <FaUnderline />
                                        </Button>
                                        <Button onClick={() => applyStyle('superscript')}>
                                            <FaSuperscript />
                                        </Button>
                                        <Button onClick={() => applyStyle('subscript')}>
                                            <FaSubscript />
                                        </Button>
                                        {/* {item.attachment.length === 0 ? */}
                                        <Button className="attchcomment-btn">
                                            <FaPaperclip />
                                            <input type="file" onChange={(e) => handleUpload(e)} ref={fileInputRef}></input>
                                        </Button>
                                        {/* : ""} */}
                                    </div>
                                    <div>
                                        <Button onClick={handleCancel}><FaTimes /></Button>
                                    </div>
                                </div>
                                <div className="CommentContent">
                                    <div contentEditable="true" id="TypeContent" className="CommentTextEntry UIFont Cmdtxtcontent"></div>
                                </div>
                                {fileAttachment.length !== 0 ? <div className='comment-attached'>
                                    {fileAttachment.map((item, b) =>
                                        <div className='comment-attached-list' key={b}>
                                            <p>{item.Filename}</p>
                                            <label>size - {item.Filesize}</label>
                                            <button onClick={() => deleteConfirm(item, b, index)}><FaRegTrashAlt /></button>
                                        </div>
                                    )}
                                </div> : ''
                                }
                                {loader ? <div className='fileupload-loder'><span className="loader"></span></div> : ''}
                                <div className="reply-footer">
                                    <Button className="save-btn" onClick={() => handleReplySave(index)}><FaPaperPlane /> Post</Button>
                                </div>
                            </div>
                        )}
                        {!queryStatus ?
                            <div className="comment-footer">
                                {editingIndex === index ? (
                                    <>
                                        <Button className="save-btn" onClick={() => handleSave(item.linenumber, item.wholematch, editedQuery)}><FaSave /> Save</Button>
                                        <Button className="cancel-btn" onClick={handleCancel}><FaTimes /> Cancel</Button>
                                    </>
                                ) : (
                                    <>
                                        {item.aqCheck ?
                                            <div className="radio-buttons">
                                                <div>
                                                    <input type="radio" name={'aqchecker' + index} id="yes" value="yes" checked={item.answerCheck === 'yes'} onChange={(e) => handleRadio(e, index)} />
                                                    <label htmlFor={'aqchecker' + index}> Yes</label>
                                                </div>
                                                <div>
                                                    <input type="radio" name={'aqchecker' + index} id="no" value="no" checked={item.answerCheck === 'no'} onChange={(e) => handleRadio(e, index)} />
                                                    <label htmlFor={'aqchecker' + index}> No</label>
                                                </div>
                                            </div> :
                                            <div className="checkbox-area">
                                                <input type="checkbox" name="querycheck" value="querycheck" onChange={() => handleMark(index)} disabled={item.replies !== undefined && item.replies.length !== 0} checked={item.repliesMark} />
                                                <label htmlFor="querycheck">No action required</label>
                                            </div>}

                                        <Button
                                            className="accept-btn"
                                            disabled={
                                                (item?.aqCheck && item?.answerCheck === 'yes') || // Disable for 'yes' radio
                                                (item?.replies?.length > 0) || // Disable if already has reply
                                                (item?.repliesMark) // Disable if marked as replied
                                            }
                                            style={{
                                                display: item?.aqCheck ?
                                                    (item?.showReplyButton ? 'inline-block' : 'none') :
                                                    'inline-block'
                                            }}
                                            onClick={() => handleReply(index)}
                                        >
                                            <FaReply /> Reply
                                        </Button>
                                        {/* <Button className="reject-btn" onClick={() => handleDelete(item.linenumber, item.wholematch)} disabled> <FaTimes /> Delete</Button>
                                        <Button className="edit-btn" onClick={() => handleEdit(index, item.query)} disabled><FaEdit /> Edit</Button> */}
                                    </>
                                )}
                            </div> : ''}
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthorQuery: state.RightSidebar.AuthorQueryData,
        isDocvalue: state.LayoutReducer.Docvalue
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        dispatchFoucs: (isNumber) => {
            dispatchFoucs(dispatch, isNumber)
        },
        dispatchAQchange: (AQcontent) => {
            dispatchAQchange(dispatch, AQcontent)
        },
        dispatchAQdelete: (AQdelete) => {
            dispatchAQdelete(dispatch, AQdelete)
        },
        dispatchAuthorQuery: (AQupdate) => {
            dispatchAuthorQuery(dispatch, AQupdate)
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(QueryTab);