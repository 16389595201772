import { useEffect, useState } from "react";
import { FaRegFile, FaFolder, FaUpload, FaEye, FaHistory, FaDownload, FaComment, FaUsers, FaComments, FaCog, FaRegUserCircle, FaList, FaTimes, FaColumns, FaRegFilePdf, FaCode, FaExclamationTriangle, FaTimesCircle, FaPaperclip, FaFileAlt, FaFileArchive, FaPencilAlt, FaImage, FaFileInvoice, FaLock, FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { MdHelpOutline } from "react-icons/md";
import Logo from "../../images/iAuthorFileIcon.png";
import Loader from "../../images/loader.gif";
import { FilelistTab, TocTab, AttachementTab, HistoryTab, ImagelistTab, SupplementaryTab } from "../leftsidecontent";
import { AuthorQueryTab, CommentTab, TrackChanges } from "../rightsidecontent";
import PdfEditor from "../pdfeditor";
import { Modal, Button, Row, Col, Dropdown, Form } from 'react-bootstrap';
import './layout.scss';
import BlueTheme from "../../images/bluetheme.png";
import DarkTheme from "../../images/draktheme.png";
import Split from '@uiw/react-split';
import { dispatchFoucs, dispatchAuthorQuery, dispatchComment, dispatchBlobcomment, dispatchTrackchanges, dispatchTrackBlob, dispatchDocDetails, dispatchTextFromEditor, dispatchMacroStatus } from "../../redux/action"
import { connect } from "react-redux";
import { Table } from "react-bootstrap";
import integraLogo from "../../images/integra.png";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { settingForm } from "./formjson";
import CodeMirrorEditor from "../editor";
import pathconfig from '../../pathconfig.json';
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";
import { toastError, toastSuccess, toastWarning } from '../toaster';
import moment from 'moment';
import io from 'socket.io-client';
import packjson from '../../../package.json';

const Layout = (props) => {
    const [sidebar, setSidebar] = useState('');
    const [rightsidebar, setRightsidebar] = useState(false);
    const [leftsidebarvalue, setLeftsidebarvalue] = useState('fileview')
    const [rightsidebarvalue, setRightsidebarvalue] = useState('')
    const [editorValue, setEditorvalue] = useState('');
    const [editorStyle, setEditorStyle] = useState('');
    const [themeStyle, setThemestyle] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [changeValue, setChangeValue] = useState('');
    const [queryResult, setQueryresult] = useState([]);
    const [errorCompile, setErrorCompile] = useState([]);
    const [errorCompileFullData, setErrorCompileFullData] = useState([]);
    const [errorShow, setErrorShow] = useState(false);
    const [errorStatus, setErrorStatus] = useState(false)
    const [layout, setLayout] = useState('editorandpdf');
    const [pdfShow, setPdfShow] = useState(false);
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [settingGroup, setSettingGroup] = useState(settingForm)
    const [onlineData, setOnlineData] = useState({})
    const [loader, setLoader] = useState(true)
    const [fileProgess, setFileProgess] = useState(false);
    const [render, setRender] = useState(false)
    const [userProfile, setUserProfile] = useState({ username: '', role: '' }); // State to store username
    const location = useLocation();
    const navigate = useNavigate();
    const [downloadTrigger, setdownloadTriger] = useState(false);
    const [errorTab, setErrorTab] = useState('errorlog');
    const [logFile, setLogFile] = useState('');
    const [filestatus, setFilestatus] = useState(false);
    const [lastsavedTime, setLastsavedTime] = useState();
    const [dragTrigger, setDragTrigger] = useState(false);
    const [validationdata, setValidationdata] = useState([]);
    const [trackData, setTrackData] = useState([]);
    const [itemFile, setItemFile] = useState([])
    const [readOnly, setReadOnly] = useState(false)
    const [trackComment, setTrackComment] = useState([]);
    const [filters, setFilters] = useState({
        Error: true, // Default to true for "Error"
        Warning: true // Default to true for "Warning"
    });
    // const [ediTextToPdf, setEdiTextToPdf] = useState({ selectedText: '', entireLine: '' })
    // const [panelHistory, setPanelHistory] = useState([]);
    // const [currentPanelIndex, setCurrentPanelIndex] = useState(-1);

    // const handlePanelChange = (newPanel) => {
    //     setPanelHistory(prev => [...prev.slice(0, currentPanelIndex + 1), newPanel]);
    //     setCurrentPanelIndex(prev => prev + 1);
    // };

    // const handleBack = () => {
    //     if (currentPanelIndex > 0) {
    //         setCurrentPanelIndex(prev => prev - 1);
    //     }
    // };
    // const handleForward = () => {
    //     if (currentPanelIndex < panelHistory.length - 1) {
    //         setCurrentPanelIndex(prev => prev + 1);
    //     }
    // };
    const [logStatus, setLogStatus] = useState(false);

    let sockectCheck = false;

    useEffect(() => {
        let docId = location.search.split('?')[1].split('=')[1];
        const initializeSocket = async () => {
            const newSocket = await setupSocketConnection(docId);
            return () => {
                if (newSocket) {
                    newSocket.disconnect();
                }
            };
        };

        initializeSocket();

        let themeValue = localStorage.getItem('theme');
        if (themeValue == null) {
            localStorage.setItem('theme', 'blue-theme');
            setThemestyle('blue-theme');
            themeValue = 'blue-theme';
            settingGroup[1].value = 'blue-theme';
            setSettingGroup(settingGroup);
        }
        else {
            setThemestyle(themeValue);
            settingGroup[1].value = themeValue;
            setSettingGroup(settingGroup);
        }
        document.body.classList.add(themeValue);
        getDocdata(docId);
        getDetails();
    }, []);

    const setupSocketConnection = async (docId) => {
        try {
            let iAsocketUrl = 'https://live-isocket-dev.azurewebsites.net/integra';
            const iAsocket = io.connect(iAsocketUrl, {
                transports: ['websocket', 'polling', 'flashsocket']
            });

            iAsocket.on('connect', async function () {
                console.log('Socket Connected');
                let result = await createRoom(docId, iAsocket);
                var Roomjoinedcount = JSON.parse(result).isRommJoined;
                if (Roomjoinedcount === 0) {
                    // window.location.href = 'https://www.surveymonkey.com/r/HMBCDK5?JID=ELS&AID=33BAF209';
                    iAsocket.disconnect();
                    // navigate(`/notification?docid=${docId}`, { state: 'error' })
                    sockectCheck = true;
                }
            });
            iAsocket.on('disconnect', function (resaon) {
                console.log('Socket Disconnected: Reason' + resaon);

            });
            return iAsocket;
        } catch (error) {
            console.error('Error in socket connection:', error);
        }
    };

    function createRoom(docid, iAsocket) {
        return new Promise((resolve, reject) => {
            try {
                var docObj = {
                    docID: docid
                }; // format for sending docID
                docObj.isCollaborative = false; //optional
                iAsocket.on('createRoom', function (data) {
                    console.log(data, 'Room created');
                    resolve(data);
                });
                iAsocket.emit('createRoom', JSON.stringify(docObj));
            } catch (e) { resolve('{}') }
        });
    }

    useEffect(() => {
        if (props.isCommentSidebar.showSidebar === 'rightbar') {
            setSidebar(props.isCommentSidebar.showSidebar)
            setRightsidebarvalue(props.isCommentSidebar.commentValue)
        }

    }, [props.isCommentSidebar])

    const layoutview = (value) => {
        setLayout(value)
    }

    const slidebarHandle = (type, value) => {
        if (sidebar === 'leftbar') {
            setLeftsidebarvalue(value)
        } else {
            setSidebar(type)
            setLeftsidebarvalue(value)
            setRightsidebarvalue('')
        }
    }

    const getDocdata = (value) => {
        let url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].get_Doc_Details}`;
        let docid = value;
        axios.get(`${url}?docid=${docid}`)
            .then((response) => {
                setSidebar('')
                setLeftsidebarvalue('')
                setOnlineData(response.data);
                console.log(response.data, 'response.data')
                let responseTrue = response.data.TocDetails.oData.ActivtyDetails.isPreviewMode === 1 ? true : false;
                if (sockectCheck) {
                    setReadOnly(sockectCheck)
                    toastWarning('This article is currently locked by another user.');
                    setLogStatus(sockectCheck)
                } else {
                    setReadOnly(responseTrue)
                }
                props.dispatchAuthorQuery(JSON.parse(response.data.queryContent));
                props.dispatchComment({ comment: JSON.parse(response.data.commentContent).comment, changeIndex: null });
                props.dispatchBlobcomment(response.data.rangeCommentContent === '{}' ? [] : JSON.parse(response.data.rangeCommentContent));
                let trackdata = response.data.trackchangesContent === '{}' ? [] : JSON.parse(response.data.trackchangesContent);
                props.dispatchTrackBlob(trackdata)
                const dataString = JSON.stringify(response.data.TocDetails.oData);
                localStorage.setItem(`${docid}_init-getdetails`, dataString);
                let GetDetailData = response.data.TocDetails.oData.ActivtyDetails.userDetails;
                let activityId = response.data.TocDetails.oData.ActivtyDetails.ActivityID;
                if (GetDetailData) {
                    setUserProfile({ username: GetDetailData.Name, role: GetDetailData.roleName });
                }
                props.dispatchDocDetails({
                    "docid": docid, "username": GetDetailData.Name, "role": GetDetailData.roleName, 'ActivityID': activityId, 'logContent': response.data.logContent
                })
                setLoader(false);
                props.StartTour(true);
            }).catch((error) => {
                console.log(error)
                navigate(`/notification?docid=${docid}`, { state: 'error' })
            })
    }

    const downloadzipfolder = async () => {
        let docId = location.search.split('?')[1].split('=')[1];
        let GetDetailDataString = localStorage.getItem(`${docId}_init-getdetails`);
        let GetDetailData = JSON.parse(GetDetailDataString);
        let outputZipPath = GetDetailData.Articledetails.OutputPath.replace('.xml', '.zip')
        const url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].blobFileExistorNot}`;
        const data = {
            InputPath: outputZipPath,
        }
        const isFileexist = await axios.post(url, data);
        if (isFileexist.data.exist === true) {
            const response = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].download}`, {
                blobUrl: outputZipPath,
                type: 'application/zip'
            }, {
                responseType: 'blob',// Important               
            });
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `${GetDetailData.Articledetails.ArticleName}.zip`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            toastSuccess(`Supporting files has been successfully downloaded.`);
        }
        else {
            toastError(`The requested file could not be found..`)
        }
    }

    const leftbarClose = () => {
        setSidebar('')
        setLeftsidebarvalue('')
    }

    const rightbarClose = () => {
        setSidebar('')
        setRightsidebarvalue('')
    }

    const editorData = (val) => {
        setErrorShow(false);
        setChangeValue('')
        setPdfShow(false)
        setEditorvalue(val)
    }

    const rightBarHandle = (type, value) => {
        if (sidebar === 'rightbar') {
            setRightsidebarvalue(value)
        } else {
            setSidebar(type)
            setRightsidebarvalue(value)
            setLeftsidebarvalue('')
        }
    }

    const themeHandle = (e) => {
        localStorage.setItem('theme', e.target.value)
        setThemestyle(e.target.value);
    }

    const saveTheme = () => {
        setModalShow(false);
        setTimeout(() => {
            let bodyClass = document.body.classList;
            if (bodyClass.contains('blue-theme')) {
                document.body.classList.remove('blue-theme');
            } else if (bodyClass.contains('dark-theme')) {
                document.body.classList.remove('dark-theme');
            }
            document.body.classList.add(themeStyle);
        }, 500);
    }

    const changeData = (val) => {
        setChangeValue(val)
    }
    const compileError = (errorValue) => {
        if (errorValue) {
            setErrorCompile(errorValue);
            setErrorCompileFullData(errorValue);
            setErrorTab('errorlog');
            setErrorShow(true);
            setEditorvalue('');
        }
    }
    const commentShow = (data) => {
        setRightsidebarvalue(data)
        setSidebar('rightbar')
    }

    const queryData = (queryData) => {
        setQueryresult(queryData)
    }

    const layoutOption = (type) => {
        setLayout(type)
    }

    const handleClose = () => {
        setShowOffcanvas(false)
    }

    const handleForm = (index, e, type) => {
        if (type === 'theme') {
            localStorage.setItem('theme', e.target.value)
            setThemestyle(e.target.value);
            let bodyClass = document.body.classList;
            if (bodyClass.contains('blue-theme')) {
                document.body.classList.remove('blue-theme');
            } else if (bodyClass.contains('dark-theme')) {
                document.body.classList.remove('dark-theme');
            }
            document.body.classList.add(e.target.value);
            setRender(!render)
        }
        settingGroup[index].value = e.target.value;
        setSettingGroup(settingGroup);
        setRender(!render)
    }

    const fileSave = (value) => {
        setFileProgess(value)
    }

    const fileSaveStatus = (val) => {
        setFilestatus(val)
        setLastsavedTime(new Date())
    }


    const errorFocus = (line) => {
        props.dispatchFoucs(null)
        setTimeout(() => {
            props.dispatchFoucs(line)
        }, 500);
    }

    const downloadFile = () => {
        setdownloadTriger(true)
        setTimeout(() => {
            setdownloadTriger(false)
        }, 1000);
    }

    const logTab = (val) => {
        setErrorTab(val)
        setErrorShow(true);
    }

    const errorLogShow = () => {
        setLogFile('')
        setErrorShow(true);
        setErrorTab('log')
        getLogData();
    }

    const LogDataFile = (val) => {
        if (val) {
            getLogData()
        }
    }

    const getLogData = async () => {
        let docId = location.search.split('?')[1].split('=')[1];
        let GetDetailDataString = localStorage.getItem(`${docId}_init-getdetails`);
        let GetDetailData = JSON.parse(GetDetailDataString);
        let outputLogPath = GetDetailData.Articledetails.OutputPath.replace('.xml', '.log')
        const url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].blobFileExistorNot}`;
        const data = {
            InputPath: outputLogPath,
        }
        const isFileexist = await axios.post(url, data);
        if (isFileexist.data.exist === true) {
            const response = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].get_log_details}`, {
                blobUrl: outputLogPath,
            });
            LogData(response.data.logBase64)
        }
    }

    const LogData = (logvalue) => {
        if (logvalue) {
            // Step 1: Decode the base64-encoded log data to a byte array
            const byteCharacters = atob(logvalue);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            // Step 2: Convert the byte array to a string
            const logString = new TextDecoder().decode(byteArray);
            // Step 3: Display the decoded log data
            setLogFile(logString)
            // Optionally, you can use the logString as needed, for example:
            // - Set it in a state for a React component
            // - Display it in an HTML element
            // - Write it to a file (if in a Node.js environment)
            // Example of setting the decoded log string in a React component state
            // setLogData(logString);
        }
    }

    const handleDragEnd = () => {
        setDragTrigger(false)
        setTimeout(() => {
            setDragTrigger(true)
        }, 100);
    };

    const validation = (value) => {
        if(value.length >0){
            setValidationdata(value)
            setErrorTab('validation')
            setErrorShow(true);
        }else{
            setValidationdata([])
        }
    }

    const reloadData = (status) => {
        if (status) {
            let docId = location.search.split('?')[1].split('=')[1];
            getDocdata(docId);
        }
    }
    const trackChangesValue = (value) => {
        setTrackData(value)
    }
    const getTrigger = (item) => {
        if (item === true) {
            getDetails()
        }
    }
    const getDetails = () => {
        let docId = location.search.split('?')[1].split('=')[1];
        let GetDetailDataString = localStorage.getItem(`${docId}_init-getdetails`);
        let GetDetailData = JSON.parse(GetDetailDataString);
        if (GetDetailData != null) {
            const getUrl = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].getAttachment}`;
            const body = {
                "Proof_Type": GetDetailData.jobdetails.ProofType,
                "JobGUID": GetDetailData.jobdetails.JobGUID,
                "docid": docId
            }
            axios.post(getUrl, body).then((response) => {
                let dataChange = response.data.data.map((item) => {
                    return {
                        ...item,
                        filetype: item.Filename.split('.')[1],
                    }
                }
                )
                let fileFilter = dataChange.filter(item => item.AttachType !== "gallery")
                setItemFile(fileFilter);
            }
            ).catch((error) => {
                console.log(error)
                navigate(`/notification?docid=${docId}`, { state: 'error' })
            })
        }
    }

    const trackfileComment = (value) => {
        setTrackComment(value)
    }

    const handleFilterChange = (event) => {
        const { name, checked } = event.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: checked
        }));
        setTimeout(() => {
            if (name === "Warning") {
                if (filters.Warning === false) {
                    let warningFilter = errorCompileFullData.filter((item) => item.type === 'Warning')
                    console.log(warningFilter, 'warningFilter')
                    setErrorCompile([...errorCompile, ...warningFilter])
                } else if (filters.Warning) {
                    if (filters.Error === false) {
                        setErrorCompile([])
                    } else {
                        let warningFilter = errorCompileFullData.filter((item) => item.type !== 'Warning')
                        setErrorCompile(warningFilter)
                    }
                }
            } else if (name === "Error") {
                if (filters.Error === false) {
                    let warningFilter = errorCompileFullData.filter((item) => item.type === 'Error')
                    setErrorCompile([...errorCompile, ...warningFilter])
                } else if (filters.Error) {
                    let warningFilter = errorCompile.filter((item) => item.type !== 'Error')
                    setErrorCompile(warningFilter)
                }
            }
        }, 100);
    };

    const styleEditingError = (value) => {
        if (value.error === true) {
            toastError(`${value.message}`)
        }
        else if (value.responseData.is_success === "false") {
            toastError('Api error')
        }
        else {
            if (value.errorObjects.length !== 0) {
                setErrorCompile(value.errorObjects)
                setErrorShow(true)
                if (value.responseData.is_error_status === "true") {
                    toastError('Macro api successfully completed with error.')
                }
                else if (value.responseData.is_error_status === "false" && value.responseData.is_warning_status === "true") {
                    toastWarning('Macro api successfully completed with warning.')
                    if (value.textcontent.length !== 0) {
                        setEditorStyle(value.textcontent)
                    }
                }
            }
            else if (value.responseData.is_error_status === "false" && value.responseData.is_warning_status === "false") {
                if (value.textcontent.length !== 0) {
                    setEditorStyle(value.textcontent)
                    toastSuccess('Macro api successfully completed.')
                }
            }
        }
    }
    const refrenceStrucutre = (data) => {
        if (data.error === true) {
            toastError(`${data.api}-${data.message}`)
        } else {
            if (data.errorObjects.length !== 0) {
                setErrorCompile(data.errorObjects)
                setErrorShow(true);
                setErrorTab('errorlog');
                if (data.responseData.is_error_status === "true") {
                    toastError(`${data.api} - api successfully completed with error.`)
                }
                else if (data.responseData.is_error_status === "false" && data.responseData.is_warning_status === "true") {
                    toastWarning('Reference ordering api successfully completed with warning.')
                    if (data.texcontent.length !== 0) {
                        setEditorStyle(data.texcontent)
                    }
                }
            }
            else if (data.responseData.is_error_status === "false" && data.responseData.is_warning_status === "false") {
                if (data.texcontent.length !== 0) {
                    setEditorStyle(data.texcontent)
                    toastSuccess('Reference ordering api successfully completed.')
                }
            }
        }
    }
    const MacroCleanUp = (data) => {
        if (data.error === true) {
            toastError(`${data.message}`)
        } else {
            if (data.errorObjects.length !== 0) {
                setErrorCompile(data.errorObjects)
                setErrorShow(true);
                setErrorTab('errorlog');
                if (data.responseData.is_error_status === "true") {
                    props.dispatchMacroStatus(false);
                    toastError('Macro api successfully completed with error.')
                }
                else if (data.responseData.is_error_status === "false" && data.responseData.is_warning_status === "true") {
                    props.dispatchMacroStatus(true);
                    toastWarning('Macro api successfully completed with warning.')
                    if (data.textcontent.length !== 0) {
                        setEditorStyle(data.textcontent)
                    }                    
                }
            }
            else if (data.responseData.is_error_status === "false" && data.responseData.is_warning_status === "false") {
                if (data.textcontent.length !== 0) {
                    setEditorStyle(data.textcontent)
                    toastSuccess('Macro api successfully completed.')
                    props.dispatchMacroStatus(true);

                }
            }
        }
    }

    // useEffect(() => {
    //     if (props.isTextEdiToLay) {
    //         console.log(props.isTextEdiToLay, 'isTextEdiToLay');
    //         setEdiTextToPdf(props.isTextEdiToLay);
    //     }
    // })

    // const editorToPdfText = () => {
    //     console.log('Editor to PDF Text:', ediTextToPdf);
    //     props.dispatchTextFromEditor(ediTextToPdf);
    // };

    return (
        <>
            {loader ?
                <div className="loader-outer">
                    <img src={Loader} alt="loader" style={{ width: '50px', marginBottom: '16px' }} />
                    <span className="loader"></span>
                </div> :
                <div className={readOnly ? 'readEditor' : ''}>
                    <div className={`layout ${sidebar}`}>
                        <Modal size="md" show={modalShow} aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton onHide={() => setModalShow(false)}>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Theme
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col lg={6}>
                                        <div className="theme-list">
                                            <img src={BlueTheme} alt="blue theme" className="img-fluid" />
                                            <div>
                                                <input type="radio" id="bluetheme" name="theme" checked={themeStyle === "blue-theme" ? true : false} value="blue-theme" onChange={themeHandle} />
                                                <label for="bluetheme">Blue Theme</label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="theme-list">
                                            <img src={DarkTheme} alt="blue theme" className="img-fluid" />
                                            <div>
                                                <input type="radio" id="darktheme" name="theme" checked={themeStyle === "dark-theme" ? true : false} value="dark-theme" onChange={themeHandle} />
                                                <label for="darktheme">Dark Theme</label>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={saveTheme}>Save</Button>
                                <Button variant="outline-secondary" onClick={() => setModalShow(false)}>Cancel</Button>
                            </Modal.Footer>
                        </Modal>
                        <header>
                            <div className="logo-side star-burst">
                                <img src={Logo} alt="logo" />
                                <h1>iAuthor</h1>
                            </div>
                            <div className="header-center">
                                <h1>{onlineData.Filename}</h1>
                            </div>
                            <div className="header-right-side">
                                <button className="help-btn" onClick={() => props.handleHelp(true)}><MdHelpOutline /> Help</button>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        <FaColumns /> Layout
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => layoutOption('editorandpdf')} className={layout === 'editorandpdf' ? 'active' : ''} > <FaColumns /> Editor & Pdf</Dropdown.Item>
                                        <Dropdown.Item onClick={() => layoutOption('editoronly')} className={layout === 'editoronly' ? 'active' : ''}> <FaCode /> Editor only</Dropdown.Item>
                                        <Dropdown.Item onClick={() => layoutOption('pdfonly')} className={layout === 'pdfonly' ? 'active' : ''}> <FaRegFilePdf /> Pdf only</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className="profile-details">
                                    <div><FaRegUserCircle /> </div>
                                    <div>
                                        <h5>{userProfile.username}</h5>
                                        <span>{userProfile.role}</span>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <div className="layout-inner">
                            <div className="left-panel">
                                <div>
                                    <ul className='tools-list'>
                                        {onlineData ? '' : <li>
                                            <button onClick={() => slidebarHandle('leftbar', 'fileview')} className={leftsidebarvalue === 'fileview' ? 'active' : ''} title="File">
                                                <FaRegFile />
                                            </button>
                                        </li>}
                                        {/* <li>
                                            <button title="New Folder">
                                                <FaFolder />
                                            </button>
                                        </li> */}
                                        <li>
                                            <button title="Attachement" onClick={() => slidebarHandle('leftbar', 'attachement')} className={leftsidebarvalue === 'attachement' ? 'active attachement-btn sixthstep' : 'attachement-btn sixthstep'}>
                                                <span>{itemFile.length}</span>
                                                <FaPaperclip />
                                            </button>
                                        </li>
                                        <li>
                                            <button title="Toc" onClick={() => slidebarHandle('leftbar', 'toc')} className={leftsidebarvalue === 'toc' ? 'active' : ''}>
                                                <FaList />
                                            </button>
                                        </li>
                                        <li><button onClick={() => slidebarHandle('leftbar', 'history')} className={leftsidebarvalue === 'history' ? 'active ninthstep' : 'ninthstep'} title="History"><FaHistory /></button></li>
                                        <li><button onClick={() => slidebarHandle('leftbar', 'image')} className={leftsidebarvalue === 'image' ? 'active eigthstep' : 'eigthstep'} title="Image"><FaImage /></button></li>
                                        <li><button onClick={() => slidebarHandle('leftbar', 'supplementary')} className={leftsidebarvalue === 'supplementary' ? 'active seventhstep' : 'seventhstep'} title="Supplementary"><FaFileInvoice /></button></li>
                                    </ul>
                                    <button className="theme-btn" onClick={() => setShowOffcanvas(true)}><FaCog /></button>
                                </div>
                                <div className={`left-sidebar ${leftsidebarvalue}`}>
                                    <FilelistTab close={leftbarClose} editorData={editorData} />
                                    <TocTab close={leftbarClose} />
                                    <AttachementTab close={leftbarClose} userProfile={userProfile} fileItems={itemFile} getTrigger={getTrigger} texData={onlineData} />
                                    <HistoryTab close={leftbarClose} leftsidebarvalue={leftsidebarvalue} reloadData={reloadData} fileReload={fileProgess} userProfile={userProfile} />
                                    <ImagelistTab close={leftbarClose} texData={onlineData} valueChange={changeValue} />
                                    <SupplementaryTab close={leftbarClose} />
                                </div>
                            </div>
                            <div className={errorShow === true ? "center-panel-group error-active" : "center-panel-group"}>
                                <div className="center-panel-group-inner">
                                    <Split className={`center-panel ${layout}`} onDragEnd={handleDragEnd}>
                                        <div className="center-panel-inner">
                                            <CodeMirrorEditor value={editorValue} changeData={changeData} commentShow={commentShow} editorStyleValue={editorStyle} onlineData={onlineData} layoutview={layoutview} menuTrigger={dragTrigger} fontSize={settingGroup[2].value} textWrap={settingGroup[3].value} editorOption={readOnly} trackChangesValue={trackChangesValue} trackfileComment={trackfileComment} styleEditingError={styleEditingError} refrenceStrucutre={refrenceStrucutre} MacroCleanUp={MacroCleanUp} />
                                        </div>
                                        <div className="center-panel-inner">
                                            <PdfEditor pdfData={onlineData} valueChange={changeValue} compileError={compileError} editorStyleValue={editorStyle} pdfShow={pdfShow} fileSave={fileSave} downloadTrigger={downloadTrigger} layoutview={layoutview} fileSaveStatus={fileSaveStatus} LogDataFile={LogDataFile} validation={validation} trackData={trackData} userProfile={userProfile} editorOption={readOnly} fileItems={itemFile} />
                                        </div>
                                    </Split>
                                    <div className="error-split-panel">
                                        <div className="error-split-header">
                                            <div>
                                                <button className={errorTab === 'errorlog' ? 'active' : ''} onClick={() => logTab('errorlog')}>Error log</button>
                                                <button className={errorTab === 'log' ? 'active' : ''} onClick={() => logTab('log')}>Log</button>
                                                <button className={errorTab === 'validation' ? 'active' : ''} onClick={() => logTab('validation')}>Validation</button>
                                            </div>
                                            <button title="Close" className="close" onClick={() => setErrorShow(!errorShow)}><FaTimes /></button>
                                        </div>
                                        {errorTab === 'errorlog' ?
                                            <div className="error-body top-filter-added">
                                                <div className="error-filter">
                                                    <div className="error-filter-inner error">
                                                        <input type="checkbox" id="error" name="Error" checked={filters.Error} value="Error" onChange={handleFilterChange} />
                                                        <label htmlFor="error"><FaTimesCircle /> Error</label>
                                                    </div>
                                                    <div className="error-filter-inner warning">
                                                        <input type="checkbox" id="warning" name="Warning" checked={filters.Warning} value="Warning" onChange={handleFilterChange} />
                                                        <label htmlFor="warning"><FaExclamationTriangle /> Warning</label>
                                                    </div>
                                                </div>
                                                {errorCompile.length > 0 ? <div className="tbl-fixed">
                                                    <Table responsive>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ minWidth: '30px' }}></th>
                                                                <th style={{ minWidth: '100px' }}>Line Number</th>
                                                                <th style={{ minWidth: '100px' }}>Type</th>
                                                                <th>Description</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {errorCompile.map((item, index) =>
                                                                <tr key={index} className={item.type} onClick={() => errorFocus(item)}>
                                                                    <td>{item.type === 'Error' ? <FaTimesCircle /> : item.type === 'Warning' ? <FaExclamationTriangle /> : " "}</td>
                                                                    <td>{item.line}</td>
                                                                    <td>{item.type} </td>
                                                                    <td>{item.message}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </div> :
                                                    <div className="no-data-center">
                                                        <p>No error log</p>
                                                    </div>
                                                }
                                            </div> :
                                            errorTab === 'log' ?
                                                <div className="error-body">
                                                    {logFile ?
                                                        <div className="log-pre-area">
                                                            <pre>{logFile}</pre>
                                                        </div> :
                                                        <div className="no-data-center">
                                                            <p>No log files</p>
                                                        </div>
                                                    }
                                                </div> :
                                                errorTab === 'validation' ?
                                                    <div className="error-body">
                                                        {validationdata ?
                                                            <div className="tbl-fixed">
                                                                <Table responsive>
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{ width: '60px' }}>S.no</th>
                                                                            <th style={{ minWidth: '80px' }}>Message</th>
                                                                            <th style={{ minWidth: '20px' }}>Position</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {validationdata.map((item, index) =>
                                                                            <tr key={index}>
                                                                                <td>{item.serial}</td>
                                                                                <td>{item.message} </td>
                                                                                <td> {item.position === 'Query' ? item.position : <button onClick={() => errorFocus(item)}>{item.position}</button>}</td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </Table>
                                                            </div> :
                                                            <div className="no-data-center">
                                                                <p>No validation</p>
                                                            </div>
                                                        }
                                                    </div> : ''
                                        }
                                    </div>
                                </div>
                                <div className="center-panel-bottom">
                                    <div className="center-panel-bottom-inner"></div>
                                    <div className="center-panel-bottom-inner">Powered by <img src={integraLogo} alt="integra logo" /> | All Rights Reserved</div>
                                    <div className="center-panel-bottom-inner">
                                        {logStatus ? <p className="lock-text"><FaLock /> Article locked by someone. </p> : ''}
                                        {fileProgess ? <div className="saving-area">
                                            <p><label className="loader_saving"></label>Saving</p>
                                        </div> : filestatus ?
                                            <div className="saving-area">
                                                <p>Last saved on {moment(lastsavedTime).format("DD MMMM YYYY - HH:mm:ss")}</p>
                                            </div>
                                            : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="right-panel">
                                <div className="right-panel-list">
                                    <ul>
                                        <li><button onClick={() => rightBarHandle('rightbar', 'query')} className={rightsidebarvalue === 'query' ? 'active thirdstep' : 'thirdstep'} title="Query"><FaComment /></button></li>
                                        <li><button onClick={() => rightBarHandle('rightbar', 'comment')} className={rightsidebarvalue === 'comment' ? 'active secondstep' : 'secondstep'} title="Comment"><FaComments /></button></li>
                                        <li><button title="Download PDF" onClick={downloadFile} className="fourthstep" ><FaDownload /></button></li>
                                        <li style={{ display: `${readOnly ? 'none' : ''}` }}><button title="Log files" className={errorShow ? 'active' : ''} onClick={errorLogShow}><FaFileAlt /></button></li>
                                        {!["Editor", "Author"].includes(userProfile.role) ? <li><button title="Support Files" onClick={downloadzipfolder}><FaFileArchive /></button></li> : ''}
                                        <li><button title="Track Changes" onClick={() => rightBarHandle('rightbar', 'trackchanges')} className={rightsidebarvalue === 'trackchanges' ? 'active fifthstep' : 'fifthstep'}><FaPencilAlt /></button></li>
                                    </ul>
                                </div>
                                <div className="right-sidebar">
                                    {rightsidebarvalue === 'query' ? <AuthorQueryTab queryData={queryResult} close={rightbarClose} valueChange={changeValue} texContent={onlineData} getTrigger={getTrigger} /> : rightsidebarvalue === 'comment' ? <CommentTab close={rightbarClose} getTrigger={getTrigger} trackComment={trackComment} /> : rightsidebarvalue === 'trackchanges' ? <TrackChanges close={rightbarClose} userProfile={userProfile} trackChangesValue={trackChangesValue} /> : ''}
                                </div>
                            </div>
                        </div>
                        <Offcanvas show={showOffcanvas} onHide={handleClose}>
                            <Offcanvas.Header>
                                <Offcanvas.Title>Settings</Offcanvas.Title>
                                <Button onClick={handleClose} title="Close"><FaTimes /></Button>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                {settingGroup.map((item, index) =>
                                    <Form.Group className="form-froup" key={index}>
                                        <Form.Label>{item.label}</Form.Label>
                                        <Form.Select aria-label="latex" onChange={(e) => handleForm(index, e, item.fieldType)} value={item.value}>
                                            {item.option.length === 0 ? <option>No Data</option> :
                                                item.option.map((list, itemIndex) =>
                                                    <option value={list.value} key={itemIndex}>{list.label}</option>
                                                )
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                )}
                            </Offcanvas.Body>
                        </Offcanvas>
                    </div >
                </div>
            }
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        isLayoutValue: state.LayoutReducer.Layoutvalue,
        isCommentSidebar: state.RightSidebar.commentSidebar,
        isAuthorQuery: state.RightSidebar.AuthorQueryData,
        isComment: state.RightSidebar.Comments,
        isTextEdiToLay: state.RightSidebar.TextoLayout

    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        dispatchDocDetails: (details) => {
            dispatchDocDetails(dispatch, details)
        },
        dispatchFoucs: (isNumber) => {
            dispatchFoucs(dispatch, isNumber)
        },
        dispatchAuthorQuery: (AQContent) => {
            dispatchAuthorQuery(dispatch, AQContent)
        },
        dispatchComment: (value) => {
            dispatchComment(dispatch, value)
        },
        dispatchBlobcomment: (blobComment) => {
            dispatchBlobcomment(dispatch, blobComment)
        },
        dispatchTrackchanges: (trackvalue) => {
            dispatchTrackchanges(dispatch, trackvalue)
        },
        dispatchTrackBlob: (track) => {
            dispatchTrackBlob(dispatch, track)
        },
        // dispatchTextFromEditor: (editorText) => {
        //     dispatchTextFromEditor(dispatch, editorText)
        // },
        dispatchMacroStatus: (macAutoSave) => {
            console.log(macAutoSave);
            dispatchMacroStatus(dispatch, macAutoSave)
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Layout);