import React, { useEffect, useRef, useContext, createContext, useState, useCallback } from 'react';
import { basicSetup } from 'codemirror';
import { EditorState, StateEffect, StateField, RangeSetBuilder, Transaction } from '@codemirror/state';
import { EditorView, keymap, Decoration, WidgetType, MatchDecorator, ViewPlugin } from '@codemirror/view';
import { connect } from "react-redux";
import { dispatchAuthorQuery, dispatchLayoutValue, dispatchCommentSidebar, dispatchCommentValue, dispatchBlobcomment, dispatchView, dispatchTrackchanges, dispatchTextFromEditor } from "../../redux/action";
import { LaTeXLanguage } from './languages/latex/latex-language.ts'
import { LanguageSupport, } from '@codemirror/language'
import { linter, lintGutter } from '@codemirror/lint';
import { indentWithTab } from "@codemirror/commands"
import { openAutocomplete } from './languages/latex/open-autocomplete.ts'
import { latexIndentService } from './languages/latex/latex-indent-service.ts'
import { shortcuts } from './languages/latex/shortcuts.ts';
import { html } from '@codemirror/lang-html';
import {
  argumentCompletionSources,
  explicitCommandCompletionSource,
  inCommandCompletionSource,
  beginEnvironmentCompletionSource,
} from './languages/latex/complete.ts'
import { isVisual } from './extensions/visual/visual.ts'
import { metadata } from './languages/latex/metadata.ts'
import { documentCommands } from './languages/latex/document-commands.ts'
import { documentEnvironmentNames } from './languages/latex/document-environment-names.ts'
import LintWorker from './languages/latex/linter/latex-linter.worker.js'
import { ToolbarItems } from './toolbar-item.js';
import { FaRegFilePdf, FaRegComment, FaEllipsisH, FaRegTrashAlt, FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { Button, Form, Modal } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import { json } from 'react-router-dom';
import { styleEditing, referanceOrdering, clearCacheFiles, CleanupEditor } from './button-function.js';
import { toastError, toastSuccess, toastWarning } from '../toaster';
import moment from 'moment';

const lintWorker = new LintWorker();

const completionSources = [
  ...argumentCompletionSources,
  inCommandCompletionSource,
  explicitCommandCompletionSource,
  beginEnvironmentCompletionSource
]
const autocompleteTheme = EditorView.baseTheme({
  '.cm-tooltip.cm-tooltip-autocomplete': {
    // shift the tooltip, so the completion aligns with the text
    marginLeft: '-4px',
  },
  '&light .cm-tooltip.cm-tooltip-autocomplete, &light .cm-tooltip.cm-completionInfo':
  {
    border: '1px lightgray solid',
    background: '#fefefe',
    color: '#111',
    boxShadow: '2px 3px 5px rgb(0 0 0 / 20%)',
  },
  '&dark .cm-tooltip.cm-tooltip-autocomplete, &dark .cm-tooltip.cm-completionInfo':
  {
    border: '1px #484747 solid',
    boxShadow: '2px 3px 5px rgba(0, 0, 0, 0.51)',
    background: '#25282c',
    color: '#c1c1c1',
  },

  // match editor font family and font size, so the completion aligns with the text
  '.cm-tooltip.cm-tooltip-autocomplete > ul': {
    fontFamily: 'var(--source-font-family)',
    fontSize: 'var(--font-size)',
  },
  '.cm-tooltip.cm-tooltip-autocomplete li[role="option"]': {
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: 1.4, // increase the line height from default 1.2, for a larger target area
    outline: '1px solid transparent',
  },
  '&light .cm-tooltip.cm-tooltip-autocomplete li[role="option"]:hover': {
    outlineColor: '#abbffe',
    backgroundColor: 'rgba(233, 233, 253, 0.4)',
  },
  '&dark .cm-tooltip.cm-tooltip-autocomplete li[role="option"]:hover': {
    outlineColor: 'rgba(109, 150, 13, 0.8)',
    backgroundColor: 'rgba(58, 103, 78, 0.62)',
  },
  '.cm-tooltip.cm-tooltip-autocomplete ul li[aria-selected]': {
    color: 'inherit',
  },
  '&light .cm-tooltip.cm-tooltip-autocomplete ul li[aria-selected]': {
    background: '#cad6fa',
  },
  '&dark .cm-tooltip.cm-tooltip-autocomplete ul li[aria-selected]': {
    background: '#3a674e',
  },
  '.cm-completionMatchedText': {
    textDecoration: 'none', // remove default underline,
  },
  '&light .cm-completionMatchedText': {
    color: '#2d69c7',
  },
  '&dark .cm-completionMatchedText': {
    color: '#93ca12',
  },
  '.ol-cm-completionType': {
    paddingLeft: '1em',
    paddingRight: 0,
    width: 'auto',
    fontSize: '90%',
    fontFamily: 'var(--source-font-family)',
    opacity: '0.5',
  },
  '.cm-completionInfo .ol-cm-symbolCompletionInfo': {
    margin: 0,
    whiteSpace: 'normal',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  '.cm-completionInfo .ol-cm-symbolCharacter': {
    fontSize: '32px',
  },
})
const trackChangesTheme = EditorView.baseTheme({
  '.cm-line': {
    overflowX: 'hidden', // needed so the callout elements don't overflow (requires line wrapping to be on)
  },
  '&light .ol-cm-change-i': {
    backgroundColor: '#2c8e304d',
  },
  '&dark .ol-cm-change-i': {
    backgroundColor: 'rgba(37, 107, 41, 0.15)',
  },
  '&light .ol-cm-change-c': {
    backgroundColor: '#f3b1114d',
  },
  '&dark .ol-cm-change-c': {
    backgroundColor: 'rgba(194, 93, 11, 0.15)',
  },
  '.ol-cm-change': {
    padding: 'var(--half-leading, 0) 0',
  },
  '.ol-cm-change-d': {
    borderLeft: '2px dotted #c5060b',
    marginLeft: '-1px',
  },
  '.ol-cm-change-callout': {
    position: 'relative',
    pointerEvents: 'none',
    padding: 'var(--half-leading, 0) 0',
  },
  '.ol-cm-change-callout-inner': {
    display: 'inline-block',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100vw',
    borderBottom: '1px dashed black',
  },
  // disable callout line in Firefox
  '@supports (-moz-appearance:none)': {
    '.ol-cm-change-callout-inner': {
      display: 'none',
    },
  },
  '.ol-cm-change-callout-i .ol-cm-change-callout-inner': {
    borderColor: '#2c8e30',
  },
  '.ol-cm-change-callout-c .ol-cm-change-callout-inner': {
    borderColor: '#f3b111',
  },
  '.ol-cm-change-callout-d .ol-cm-change-callout-inner': {
    borderColor: '#c5060b',
  }
})

const changeMark = (text, meta) => Decoration.mark({
  tagName: 'span',
  class: 'ol-cm-change ol-cm-change-i',
  attributes: { 'data-text': text, 'data-meta': JSON.stringify(meta) }
});

const commentMark = (comment, cid, commentmeta) => Decoration.mark({
  tagName: 'span',
  class: 'ol-cm-change ol-cm-change-c',
  attributes: { 'data-comment': comment, 'data-id': cid, 'data-meta': JSON.stringify(commentmeta) },
});

class ChangeDeletedWidget extends WidgetType {
  constructor(text) {
    super()
    this.text = text;
  }

  toDOM() {
    const widget = document.createElement('span')
    widget.classList.add('ol-cm-change')
    widget.classList.add('ol-cm-change-d')
    // widget.attributes.add({ 'data-deleted-text': this.text });

    return widget
  }

  eq() {
    return true
  }
}

const deleteMark = (text, metadata, id, opType) => Decoration.widget({
  widget: new ChangeDeletedWidget(text, metadata),
  class: 'ol-cm-change ol-cm-change-d',
  side: 1,
  id,
  metadata: JSON.stringify(metadata),
  text,
  opType
})

class ChangeCalloutWidget extends WidgetType {
  constructor(opType
  ) {
    super()
    this.opType = opType;
  }

  toDOM() {
    const widget = document.createElement('span')
    widget.className = 'ol-cm-change-callout'
    widget.classList.add(`ol-cm-change-callout-${this.opType}`)

    const inner = document.createElement('span')
    inner.classList.add('ol-cm-change-callout-inner')
    widget.appendChild(inner)

    return widget
  }

  eq(widget) {
    return widget.opType === this.opType
  }

  updateDOM(element) {
    element.className = 'ol-cm-change-callout'
    element.classList.add(`ol-cm-change-callout-${this.opType}`)
    return true
  }
}

// Change tracking effect and field
const insertionEffect = StateEffect.define();
const deletionEffect = StateEffect.define();
const commentEffect = StateEffect.define();
const clearCommentEffect = StateEffect.define();
const clearEffect = StateEffect.define();

const changeField = StateField.define({
  create(state) {
    if (state.doc.length > 0)
      return loadTrackChanges(state);
    else
      return Decoration.none;
  },
  update(decorations, tr) {

    let builder = new RangeSetBuilder();

    // Map existing decorations through the changes
    decorations = decorations.map(tr.changes);

    // Collect new decorations from effects
    let newDecorations = [];
    let clearDecorations = [];
    for (let effect of tr.effects) {
      if (effect.is(insertionEffect)) {
        newDecorations.push({ from: effect.value.from, to: effect.value.to, opType: 'i', decoration: changeMark(effect.value.text, { Username: userProfile.Name, role: userProfile.roleName, Date_Time: new Date().toLocaleString(), cmdtype: 'trackchanges', status: '' }), direction: '' });
      } else if (effect.is(deletionEffect)) {

        newDecorations.push({ from: effect.value.from, to: effect.value.to, opType: 'd', decoration: deleteMark(effect.value.text, { Username: userProfile.Name, role: userProfile.roleName, Date_Time: new Date().toLocaleString(), cmdtype: 'trackchanges', status: '' }), direction: effect.value.direction });
      }
      else if (effect.is(commentEffect)) {
        newDecorations.push({ from: effect.value.from, to: effect.value.to, opType: 'c', decoration: commentMark(effect.value.comment, effect.value.cid, effect.value.commentmeta), direction: '' });
      }
      else if (effect.is(clearCommentEffect)) {
        // newDecorations = newDecorations.filter(deco => !(deco.from === effect.value.from && deco.to === effect.value.to && deco.decoration.spec.class === 'ol-cm-change ol-cm-change-c'));
        clearDecorations.push({ from: effect.value.from, to: effect.value.to, opType: 'rc', direction: '' })
      }
      else if (effect.is(clearEffect)) {
        // Clear decorations within the range specified by the clearEffect
        clearDecorations.push({ from: effect.value.from, to: effect.value.to, opType: 'r', direction: '' });
      }
    }

    // Preserve existing decorations
    decorations.between(0, tr.newDoc.length, (from, to, value) => {
      if (value.spec.class != undefined) {
        let temptype = '';
        if (value.spec.class.includes('ol-cm-change-i'))
          temptype = 'i';
        else if (value.spec.class.includes('ol-cm-change-c'))
          temptype = 'c';
        else if (value.spec.class.includes('ol-cm-change-d'))
          temptype = 'd';

        if (temptype == 'd') {
          let res = clearDecorations.filter(d => d.to == from || d.from == from);
          if (res.length > 0) {
            let newtextlength = res[0].to - res[0].from;
            if (newtextlength > 0 && newtextlength != value.spec.text.length) {
              let str = value.spec.text;
              let start = 0;
              let end = newtextlength;
              let newStr = str.substring(0, start) + str.substring(end);
              value.spec.text = newStr;
              newDecorations.push({
                from,
                to,
                opType: temptype,
                decoration: value
                , direction: ''
              });
            }
          }
          else {
            res = clearDecorations.filter(d => d.to == from && d.from == from)
            if (res.length == 0) {
              newDecorations.push({
                from,
                to,
                opType: temptype,
                decoration: value,
                direction: ''
              });
            }
          }
        }
        else if (temptype == 'i') {
          let res = clearDecorations.filter(d => d.from == from && d.to == to);
          if (res == undefined || res.length == 0) {
            let newStr = tr.startState.doc.sliceString(from, to);
            if (newStr.length > 0) {
              let str = value.spec.attributes['data-text'];
              if (str != newStr)
                value.spec.attributes['data-text'] = newStr;
              newDecorations.push({
                from,
                to,
                opType: temptype,
                decoration: value,
                direction: ''
              });
            }
            else {
              newDecorations.push({
                from,
                to,
                opType: temptype,
                decoration: value,
                direction: ''
              });
            }
          }
        }
        else {
          let res = clearDecorations.filter(d => d.from == from && d.to == to);
          if ((res == undefined || res.length == 0) && (to > 0)) {
            newDecorations.push({
              from,
              to,
              opType: temptype,
              decoration: value,
              direction: ''
            });
          }
        }

      }
    });

    // Sort decorations by the `from` position
    newDecorations = newDecorations.sort((a, b) => {
      if (a.from === b.from && a.opType === b.opType) {
        return a.to - b.to;
      }
      return a.from - b.from;
    });

    console.log(newDecorations);

    // Add sorted decorations to the builder, ensuring no duplicates
    let lastFrom = -1, lastTo = -1, lastDecoration = {};
    let finalDecorations = [];
    for (let { from, to, decoration, opType, direction } of newDecorations) {
      if (from !== lastFrom || to !== lastTo || decoration !== lastDecoration) {
        finalDecorations.push({ from, to, decoration, opType, direction });
        lastFrom = from;
        lastTo = to;
        lastDecoration = decoration;

      }
    }

    lastFrom = -1;
    lastTo = -1;
    let ind = 0;
    let lasttype = '';
    let text = '';
    for (let { from, to, decoration, opType, direction } of finalDecorations) {
      if (ind === 0) {
        lastFrom = from;
        lastTo = to;
        lastDecoration = decoration;
        lasttype = opType;
        text = textmap(opType, decoration, text);
      }
      else if (opType !== lasttype) {
        let mdata = createmeta(lastDecoration, lastFrom, lastTo, lasttype)
        builder = addbuilder(builder, lasttype, lastFrom, lastTo, text, mdata, lastDecoration);
        lastFrom = from;
        lastTo = to;
        lastDecoration = decoration;;
        lasttype = opType;
        text = textmap(opType, decoration, '');
      }
      else if (opType === 'd' && lasttype === 'd') {
        if (lasttype === 'd') {
          if (lastFrom === from) {

            text = textmap(opType, decoration, text, direction);
          } else if (lastTo > from) {
            text = textmap(opType, decoration, text, direction);
          } else {
            let mdata = createmeta(lastDecoration, lastFrom, lastTo, lasttype)
            builder = addbuilder(builder, lasttype, lastFrom, lastTo, text, mdata, lastDecoration);
            lastFrom = from;
            lastTo = to;
            lastDecoration = decoration;
            lasttype = opType;
            text = textmap(opType, decoration, '');
          }
        }
      }
      else if (opType === 'i' && lasttype === 'i') {
        if (lasttype === 'i') {
          if (lastFrom === from || lastTo >= from) {
            text = textmap(opType, decoration, text);
            if (lastTo < to)
              lastTo = to;
          } else {
            let mdata = createmeta(lastDecoration, lastFrom, lastTo, lasttype)
            builder = addbuilder(builder, lasttype, lastFrom, lastTo, text, mdata, lastDecoration);
            lastFrom = from;
            lastTo = to;
            lastDecoration = decoration;
            lasttype = opType;
            text = textmap(opType, decoration, '');
          }
        }
      }
      else {
        let mdata = createmeta(lastDecoration, lastFrom, lastTo, lasttype)
        builder = addbuilder(builder, lasttype, lastFrom, lastTo, text, mdata, lastDecoration);
        lastFrom = from;
        lastTo = to;
        lastDecoration = decoration;
        lasttype = opType;
        text = textmap(opType, decoration, '');
      }
      ind = ind + 1;
      if (finalDecorations.length === ind) {

        let mdata = createmeta(lastDecoration, lastFrom, lastTo, lasttype)
        builder = addbuilder(builder, lasttype, lastFrom, lastTo, text, mdata, lastDecoration);
      }
    }
    saveTrackChanges(tr.startState);
    return builder.finish();
  },
  provide: field => EditorView.decorations.from(field),
});
const createmeta = (decoration, from, to, type) => {

  const meta = {
    Username: '',
    role: '',
    Date_Time: new Date().toLocaleString(),
    cmdtype: '',
    status: ''
  }
  let metadata = {};
  if (type == 'd') {
    metadata = JSON.parse(decoration.spec.metadata)
  }
  else {
    metadata = JSON.parse(decoration.spec.attributes['data-meta'] ? decoration.spec.attributes['data-meta'] : '{}');
  }

  if (type === 'c') {
    meta.from = from;
    meta.to = to;
    meta.Username = metadata.Username;
    meta.role = metadata.role;
    meta.Date_Time = metadata.Date_Time;
    meta.cmdtype = 'Comments';
  } else {
    meta.Username = metadata.Username;
    meta.role = metadata.role;
    meta.Date_Time = metadata.Date_Time;
    meta.cmdtype = 'trackchanges';
    meta.status = '';
    meta.from = from;
    meta.to = to;
  }
  return meta;
}

const textmap = (type, decoration, text, direction = '') => {
  if (type === 'd') {
    if (direction === 'B') {
      text = decoration.spec.text + text;
    }
    else
      text = text + decoration.spec.text;
  }
  else if (type === 'i')
    text = text + decoration.spec.attributes['data-text']
  else if (type === 'c')
    text = text + decoration.spec.attributes['data-comment']
  return text;
}
const addbuilder = (builder, type, lastFrom, lastTo, text, mdata, deco) => {
  if (type === 'd')
    builder.add(lastFrom, lastFrom, deleteMark(text, mdata, '', type));
  else if (type === 'i')
    builder.add(lastFrom, lastTo, changeMark(text, mdata));
  else if (type === 'c')
    builder.add(lastFrom, lastTo, commentMark(text, deco.decoration !== undefined ? deco.decoration.spec.attributes['data-id'] : deco.spec.attributes['data-id'], mdata));
  return builder;
}
const trackChanges = EditorState.transactionFilter.of(tr => {
  if (!tr.docChanged) return tr;
  let effects = [];
  tr.changes.iterChanges((fromA, toA, fromB, toB) => {
    if (fromA !== toA) { // Deletion
      effects.push(deletionEffect.of({ from: fromA, to: toA }));
    }
    if (fromB !== toB) { // Insertion
      effects.push(insertionEffect.of({ from: fromB, to: toB }));
    }
  });
  return [tr, { effects }];
});

// Function to deserialize decorations
function deserializeDecorations(parsedData, docLength) {
  let decorations = [];
  // let parsedData = JSON.parse(data);

  for (let deco of parsedData) {
    if (deco.type === 'insertion') {
      decorations.push({
        from: deco.from,
        to: deco.to,
        type: 'i',
        Username: deco.Username,
        text: deco.text,
        status: deco.status,
        Date_Time: deco.Date_Time,
        cmdtype: deco.cmdtype,
        decoration: deco.decoration,
        startSide: 0, // Ensure startSide is defined
      });
    } else if (deco.type === 'deletion') {
      decorations.push({
        from: deco.from,
        to: deco.to,
        type: 'd',
        Username: deco.Username,
        text: deco.text,
        status: deco.status,
        Date_Time: deco.Date_Time,
        cmdtype: deco.cmdtype,
        decoration: deco.decoration,
        startSide: 0, // Ensure startSide is defined
      });
    }
    else if (deco.cmdtype === 'Comments') {
      decorations.push({
        from: deco.from,
        to: deco.to,
        decoration: commentMark(deco.Cmdcomtents, deco.cid, {
          Username: deco.Username,
          text: deco.Cmdcomtents,
          status: '',
          Date_Time: deco.Date_Time,
          cmdtype: deco.cmdtype
        }),
        type: 'c',
        Username: deco.Username,
        text: deco.text,
        status: deco.status,
        Date_Time: deco.Date_Time,
        cmdtype: deco.cmdtype,
        startSide: 0, // Ensure startSide is defined 
      });
    }
  }

  // Sort decorations by `from` position and `startSide`
  decorations.sort((a, b) => {
    if (a.from !== b.from) {
      return a.from - b.from;
    }
    return a.startSide - b.startSide;
  });

  let builder = new RangeSetBuilder();
  for (let deco of decorations) {
    let text = textmap(deco.type, deco.decoration, '');
    let mdata = createmeta(deco.decoration, deco.from, deco.to, deco.type)
    builder = addbuilder(builder, deco.type, deco.from, deco.to, text, mdata, deco);
    // builder.add(from, to, decoration);
  }

  return builder.finish();
}

// Function to serialize decorations
function serializeDecorations(decorations, doc) {
  let serialized = [];
  let serializedcomment = [];
  decorations.between(0, doc.length, (from, to, decoration) => {
    if (decoration.spec.class.includes('ol-cm-change-i')) {
      let mdata = JSON.parse(decoration.spec.attributes['data-meta']);
      let text = decoration.spec.attributes['data-text'];
      serialized.push({
        type: 'insertion',
        from,
        to,
        decoration: decoration,
        text: text,
        Username: mdata.Username,
        Date_Time: mdata.Date_Time,
        cmdtype: mdata.cmdtype,
        status: mdata.status,
        role: mdata.role
      });
    } else if (decoration.spec.class.includes('ol-cm-change-d')) {
      let mdata = JSON.parse(decoration.spec.metadata);
      serialized.push({
        type: 'deletion',
        from,
        to,
        decoration: decoration,
        text: mdata.text,
        Username: mdata.Username,
        Date_Time: mdata.Date_Time,
        cmdtype: mdata.cmdtype,
        status: mdata.status,
        role: mdata.role
      });
    } else if (decoration.spec.class.includes('ol-cm-change-c')) {
      // serialized.push({ type: 'comment', from, to, });
      serializedcomment.push({ type: 'comment', from, to, decoration, comment: decoration.spec.attributes['data-comment'], cid: decoration.spec.attributes['data-id'], commentmeta: decoration.spec.attributes['data-meta'] });
    }
  });
  return { serialized, serializedcomment };
}

let Comment;
let TrackchangesArrayValue;
let userProfile;
let docid;
let ActivityID;
// Function to save the track changes
function saveTrackChanges(state) {
  let doc = state.doc;
  let decorations = state.field(changeField);
  let serializedDecorations = serializeDecorations(decorations, doc);
  Comment = serializedDecorations.serializedcomment;
  TrackchangesArrayValue = serializedDecorations.serialized;
}

// Function to load the track changes
function loadTrackChanges(state) {
  let savedData = TrackchangesArrayValue;
  let commentsData = Comment;
  if (savedData === undefined)
    savedData = [];
  if (commentsData === undefined)
    commentsData = [];
  let data = [...commentsData, ...savedData];
  if (data.length === 0)
    return Decoration.none;
  else
    return deserializeDecorations(data);
}

const annotationsTheme = EditorView.baseTheme({
  '.cm-gutter-lint': {
    order: -1,
  },
})

// Example of a simple custom linter for TeX
const texLinter = linter(view => {
  let diagnostics = [];
  const docText = view.state.doc.toString();
  let errors = lintWorker.Parse(docText).errors;
  for (let index = 0; index < errors.length; index++) {
    const error = errors[index];
    diagnostics.push(
      {
        from: error.startPos,
        to: error.endPos,
        severity: error.type,
        message: error.text,
      }
    )
  }
  return diagnostics;
});

class HighlightWidget extends WidgetType {
  constructor(content, cid) {
    super();
    this.content = content;
    this.cid = cid;
  }

  eq(other) {
    return other.text === this.text && other.cid === this.cid;
  }

  toDOM() {
    let span = document.createElement("span");
    span.className = "highlighted-text";
    span.textContent = this.content;
    span.setAttribute("cid", this.cid);
    return span;
  }

  ignoreEvent() {
    return false;
  }
}

const highlightMatcher = new MatchDecorator({
  regexp: /\[([^\]]+)\]\(([^)]+)\)/g,
  decoration: match => Decoration.replace({
    widget: new HighlightWidget(match[1], match[2])
  })
});

const placeholders = ViewPlugin.fromClass(class {
  constructor(view) {
    this.placeholders = highlightMatcher.createDeco(view);
  }
  update(update) {
    this.placeholders = highlightMatcher.updateDeco(update, this.placeholders);
  }
}, {
  decorations: instance => instance.placeholders,
  provide: plugin => EditorView.atomicRanges.of(view => {
    return view.plugin(plugin)?.placeholders || Decoration.none;
  })
});




const CodeMirrorEditor = (props) => {
  const editorRef = useRef();
  const [state, setState] = useState(null);
  const [view, setView] = useState(null);
  const [lang, setLang] = useState('latex');
  const [visual, setVisual] = useState(true);
  const [fileContent, setFileContent] = useState('');
  const [trackContent, setTrackContent] = useState('')
  const [currentFile, setCurrentFile] = useState(null);
  const [stateDoc, setStateDoc] = useState({});
  const [selectedText, setSelectedText] = useState({ linenumber: 0 });
  const [showCommentButton, setShowCommentButton] = useState(true);
  const [comments, setComments] = useState({});
  // const [commentsValue, setCommentsValue] = useState([]);
  const [trackChangesArrayData, setTrackChangesArrayData] = useState([]);
  const [isTrack, setIsTrack] = useState(true);
  const [fontSize, setFontSize] = useState();
  const [textwrap, setTextWrap] = useState(true);
  const prevCid = useRef(null); // Ref for previous cid value
  const location = useLocation();
  const buttonResize = useRef(null);
  const subChildButton = useRef(null);
  const [profile, setProfile] = useState();
  const [ActivityName, setActivityName] = useState('')
  const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0 });
  const [query, setQuery] = useState([]);
  const [showProgress, setShowprogress] = useState(false);
  const [progressDetails, setProgressDetails] = useState(false);
  const [numberedContent, setNumberedContent] = useState('');
  const [ediTextToPdf, setEdiTextToPdf] = useState({ selectedText: '', entireLine: '' })
  const [TextlineNumber, setTextLineNumber] = useState(null)
  const [ApiStatusRunning, setApiStatusRunning] = useState('')
  useEffect(() => {
    setProfile({ docid: props.isDocDetails.docid, username: props.isDocDetails.username, role: props.isDocDetails.role, ActivityID: props.isDocDetails.ActivityID })
  }, [props.isDocDetails]);

  // const docid = location.search.split('?')[1].split('=')[1];
  // let a = JSON.parse(localStorage.getItem(`${docid}_init-getdetails`));
  // const profile = a.ActivtyDetails.userDetails;

  const tagArray = ['\\\\textbf{', '\\\\textit{', '\\\\underline{', '\\\\href{', '\\\\begin{itemize}', '\\\\begin{enumerate}', '\\\\section{', '\\\\subsection{', '\\\\subsubsection', '\\\\paragraph', '\\\\subparagraph'];

  const handleUndo = EditorView.updateListener.of((update) => {
    if (["Editor", "Author", "Copy Editor"].includes(profile?.role)) {
      if (update.transactions.some(tr => tr.docChanged)) {
        let trackdetails = [];
        if (TrackchangesArrayValue.length > 0)
          trackdetails = TrackchangesArrayValue;
        update.transactions.forEach(tr => {
          let effects = [];
          const userEvent = tr.annotation(Transaction.userEvent);
          console.log(userEvent, 'userevent')
          if (userEvent !== 'undo' && userEvent !== 'redo') {
            tr.changes.iterChanges((fromA, toA, fromB, toB) => {
              if (fromA !== toA) {
                if (userEvent == undefined) {
                  const deletedText = update.startState.doc.sliceString(fromA, toA);
                  effects.push(deletionEffect.of({ from: fromA, to: toA, text: deletedText, direction: '' }));
                }
                else if (userEvent.includes('delete.selection') || userEvent.includes('delete.backward') || userEvent.includes('delete.forward') || userEvent.includes('input.type')) {
                  let fres = trackdetails.filter(deco => deco.from <= fromA && deco.to >= toA && deco.decoration.spec.class.includes('ol-cm-change-i'))
                  if (fres == undefined || fres.length <= 0) {
                    const deletedText = update.startState.doc.sliceString(fromA, toA);
                    if (userEvent.includes('delete.backward'))
                      effects.push(deletionEffect.of({ from: fromA, to: toA, text: deletedText, direction: 'B' }));
                    else if (userEvent.includes('delete.forward'))
                      effects.push(deletionEffect.of({ from: fromA, to: toA, text: deletedText, direction: 'F' }));
                    else
                      effects.push(deletionEffect.of({ from: fromA, to: toA, text: deletedText, direction: '' }));
                  }
                }
              }
              if (fromB !== toB) {
                if (userEvent === 'deletereject') {
                  effects.push(clearCommentEffect.of({ fromB, fromB }));
                }
                else {
                  const text = update.state.doc.sliceString(fromB, toB);
                  effects.push(insertionEffect.of({ from: fromB, to: toB, text: text }));
                }
              }
            });
          } else if (userEvent == 'undo' || userEvent == 'redo') {
            tr.changes.iterChanges((fromA, toA, fromB, toB) => {
              effects.push(clearEffect.of({ from: fromA, to: toB }));
            });
          }
          if (effects.length == 2 && userEvent !== 'undo' && userEvent !== 'redo') {
            if (effects[0].value.from == effects[1].value.from) {
              let cnt = effects[1].value.text;
              let cnt1 = effects[0].value.text;
              tagArray.forEach(element => {
                //create regex pattern using element
                let pattern = new RegExp('^' + element, 'i');
                //match pattern in cnt
                let firstmatch = pattern.exec(cnt.trim());
                let secondmatch = pattern.exec(cnt1.trim());
                if (firstmatch != null) {
                  // pattern = new RegExp('//end{(?:itemize|enumerate)}$', 'i');
                  // const isMatch = pattern.test(cnt);
                  // if (isMatch) {
                  // }
                  effects.shift();
                }
                else if (secondmatch != null) {
                  let fres = trackdetails.filter(deco => deco.from <= effects[1].value.from && deco.to >= effects[1].value.to && deco.decoration.spec.class.includes('ol-cm-change-i'))
                  if (fres != undefined && fres.length > 0) {
                    let eff = clearEffect.of({ from: effects[1].value.from, to: effects[1].value.to });
                    effects = [];
                    effects.push(eff);
                  }
                  else {
                    // effects.shift();
                  }
                }
              });
            }
          }
          if (effects.length > 0)
            update.view.dispatch({ effects });
        });
      }
    }
  });

  const handleReplace = EditorView.updateListener.of((update) => {
    if (update.transactions.some(tr => tr.docChanged)) {
      update.transactions.forEach(tr => {
        const userEvent = tr.annotation(Transaction.userEvent);
        if (userEvent === 'input.replace' || userEvent === 'input.replace.all') {
          const searchText = document.querySelector('input[name="search"]').value;
          const replaceText = document.querySelector('input[name="replace"]').value;
          console.log(state, 'state')
          if (state) {
            // Step 1: Count occurrences
            const docText = fileContent;
            const regex = new RegExp(searchText, 'g');
            const count = (docText.match(regex) || []).length;
            if (count > 0) {
              // Step 2: Confirm replacement
              const userConfirmed = window.confirm(`Found ${count} occurrences. Do you want to replace them with '${replaceText}'?`);
              if (userConfirmed) {
                // Step 3: Replace text
                state.dispatch(
                  state.update({
                    changes: {
                      from: 0,
                      to: docText.length,
                      insert: docText.replace(regex, replaceText),
                    },
                  })
                );
                //setReplacementCount(count);
              }
            } else {
              alert('No occurrences found.');
            }
          }
        }
      });
    }
  });

  const latex = new LanguageSupport(LaTeXLanguage,
    [shortcuts(),
      documentCommands,
      documentEnvironmentNames,
    latexIndentService(),
    // EditorState.lineSeparator("\r\n"),
    EditorState.tabSize.of(4),
    metadata(),
    openAutocomplete(),
    ...completionSources.map(completionSource =>
      LaTeXLanguage.data.of({
        autocomplete: completionSource,
      })
    ),
      autocompleteTheme,
      changeField,
      handleUndo,
      handleReplace,
      trackChangesTheme,
    textwrap ? EditorView.lineWrapping : [],
    ]);

  // useEffect(() => {
  //   if (state !== null)
  //     // loadTrackChanges(state);
  //     if (props.isBlobComment.length !== undefined) {
  //       props.isBlobComment.map((element) =>
  //         addComment(view, element.from, element.to, element.comment, element.cid, element.commentmeta)
  //       )
  //     }
  // }, [state])

  useEffect(() => {
    if (props.value) {
      setFileContent(props.value.value.result);
      setCurrentFile(props.value.name)
    }
    if (props.onlineData) {
      setFileContent(props.onlineData.Content);
      setCurrentFile(props.onlineData.Filename);
    }
    if (props.fontSize) {
      setFontSize(props.fontSize)
    }
    if (props.textWrap) {
      setTextWrap(props.textWrap !== 'No' ? true : false)
    }
    if (props.editorStyleValue) {
      setFileContent(props.editorStyleValue);
    }
  }, [props.value, props.onlineData, props.fontSize, props.textWrap, props.editorStyleValue])

  useEffect(() => {
    if (props.onlineData) {
      if (profile?.role === 'Author') {
        setTrackContent(props.onlineData.Content);
      }
    }
  }, [profile]);

  const readOnlyRanges = EditorView.decorations.compute(['doc'], state => {
    const decorations = []
    const regex = /\\(journalvolume|journalid|journalcode|journaltitle|abbjournaltitle|journalissue|issn|pissn|eissn|publishername|publisherloc|articletype|articleid|articlenumber|DOI|PII|received|revised|accepted|pubdate|copyrightstatement|copyrightyear|copyrightholder|copyrighttype|license)\{[^}]*\}|\\\b(received|revised|accepted)\[[^\]]*\]\{[^}]*\}/g;
    let match

    while ((match = regex.exec(state.doc.toString())) !== null) {
      decorations.push(Decoration.replace({
        widget: new ReadOnlyWidget(match[0]),
        inclusive: true,
        block: false
      }).range(match.index, match.index + match[0].length))
    }

    return Decoration.set(decorations)
  })

  class ReadOnlyWidget extends WidgetType {
    constructor(content) {
      super()
      this.content = content
    }

    eq(other) { return other.content == this.content }

    toDOM() {
      let span = document.createElement("span")
      span.className = "cm-readonly"
      span.textContent = this.content
      return span
    }

    ignoreEvent() { return true }
  }

  const readOnlyTheme = EditorView.baseTheme({
    '.cm-readonly': {
      background: '#f0f0f0',
      color: '#888',
      cursor: 'not-allowed',
      userSelect: 'none'
    }
  })

  const preventReadOnlyEdit = EditorState.transactionFilter.of(tr => {
    if (!tr.docChanged) return tr

    let changes = []
    tr.changes.iterChanges((fromA, toA, fromB, toB, inserted) => {
      let inReadOnly = false
      tr.startState.field(changeField).between(fromA, toA, (from, to, value) => {
        if (value.spec.class && value.spec.class.includes('cm-readonly')) {
          if (from < toA && to > fromA) inReadOnly = true
        }
      })
      // if (!inReadOnly) {
      //   changes.push({from: fromA, to: toA, insert: inserted})
      // }
    })

    return changes.length ? [tr, { changes }] : tr
  })



  useEffect(() => {
    if (!editorRef.current) return;

    // Conditional extensions based on role
    const readOnlyExtensions = profile?.role === "Author"
      ? [readOnlyRanges, readOnlyTheme, preventReadOnlyEdit]
      : [];

    const startState = EditorState.create({
      doc: fileContent,
      extensions: [
        basicSetup,
        latex,
        html(),
        texLinter,
        placeholders,
        updateListener,
        EditorState.readOnly.of(props.editorOption),
        lintGutter({
          hoverTime: 0,
        }),

        annotationsTheme,
        keymap.of([indentWithTab]),
        // readOnlyRanges,
        // readOnlyTheme,
        // preventReadOnlyEdit
        ...readOnlyExtensions, // Conditionally applied extensions
      ],
    });

    const view = new EditorView({
      state: startState,
      parent: editorRef.current,
      dispatch: (transaction) => {
        view.update([transaction]);
        const selection = view.state.selection;
        let text = '';
        let line = null;
        let from = null;
        let to = null;
        for (const range of selection.ranges) {
          text += view.state.doc.sliceString(range.from, range.to);
          line = view.state.doc.lineAt(range.from).number;
          from = range.from;
          to = range.to;
        }
        if (line !== "") {
          setShowCommentButton(false);
          setSelectedText({ linenumber: line, selectText: text, fromLine: from, toLine: to });
          props.dispatchCommentSidebar({ selectValue: { linenumber: line, selectText: text, fromLine: from, toLine: to } });

          // Add this line to send selected text and entire line
          if (text.trim() !== "") {
            props.dispatchTextFromEditor({ selectedText: text, entireLine: view.state.doc.line(line).text })
            console.log({ selectedText: text, entireLine: view.state.doc.line(line).text }, 'ediTextToPdf')

            // props.dispatchTextFromEditor(ediTextToPdf);
          }
        }
      }
    });

    setStateDoc(startState);
    setLang(startState.facet(latex)?.name);
    setState(startState);
    setView(view);
    props.dispatchView(view)
    setVisual(isVisual(view))
    // Apply custom CSS for font size
    view.dom.style.fontSize = `${fontSize}px`;
    // console.log(view.dom.style.fontSize, 'view')

    const handleContextMenu = (event) => {
      event.preventDefault();
      const { clientX, clientY } = event;
      const { left, top } = editorRef.current.getBoundingClientRect();
      const selection = view.state.selection;
      let text = '';
      for (const range of selection.ranges) {
        text += view.state.doc.sliceString(range.from, range.to);
      }
      //const selectedText1 = view.state.getSelection();
      if (text.length !== 0) {
        setContextMenu({
          visible: true,
          x: clientX - (left + 20),
          y: clientY - top,
        });
      };
    }
    const handleClickOutside = () => {
      setContextMenu({
        visible: false,
        x: 0,
        y: 0,
      });
    }
    //right click
    editorRef.current.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('click', handleClickOutside);
    editorRef.current.addEventListener('click', (event) => {
      const position = view.posAtCoords({ x: event.clientX, y: event.clientY });

      if (position !== null) {
        console.log("Position in the document:", position);
      } else {
        console.log("Click is outside the document");
      }
    });
    view.focus();
    return () => {
      view.destroy();
      // editorRef.current.removeEventListener('contextmenu', handleContextMenu);
      // document.removeEventListener('click', handleClickOutside);
    };
  }, [fileContent, profile]);

  // const editorToPdfText = () => {
  //   console.log('Editor to PDF Text:', ediTextToPdf);
  //   props.dispatchTextFromEditor(ediTextToPdf);
  // };

  useEffect(() => {
    if (trackContent.length !== 0) {
      if (!editorRef.current) return;
      function processHTMLString(htmlString) {
        const trackChanges = [];
        const comments = [];
        const regex = /\\(ins|del|cmt)\[([\s\S]*?)\]\{((?:[^{}]*|\{(?:[^{}]*|\{[^{}]*\})*\})*)\}/g;
        let match;
        htmlString = htmlString.replace(/\r\n/g, '\n');
        while ((match = regex.exec(htmlString)) !== null) {
          let [fullMatch, command, jsonString, content] = match;
            let jsonStringModified = jsonString
                .replace(/itempslash"/g, '\\"')   // Adjust backslashes
                .replace(/\\_/g, '_')
                .replace(/\\\$/g, '$')
                .replace(/\\%/g, '%')
                .replace(/\\#/g, '#')
                .replace(/iocb/g, '{')
                .replace(/iccb/g, '}');
    
            // Attempt to parse JSON safely
            let valueObj;
            try {
                valueObj = JSON.parse(jsonStringModified);
            } catch (error) {
                console.error("JSON parsing error:", error);
                continue;
            }
    
            const fromPosition = match.index;
            valueObj.from = fromPosition;
            let toPosition;
            if (command === 'del') {
                htmlString = htmlString.slice(0, fromPosition) + htmlString.slice(fromPosition + fullMatch.length);
                toPosition = fromPosition;
                valueObj.to = toPosition;
                trackChanges.push(valueObj);
                regex.lastIndex = fromPosition; // Reset to allow capturing immediately following tags
            } else if (command === 'ins') {
                htmlString = htmlString.slice(0, fromPosition) + content + htmlString.slice(fromPosition + fullMatch.length);
                toPosition = fromPosition + content.length;
                valueObj.to = toPosition;
                trackChanges.push(valueObj);
                regex.lastIndex = fromPosition + content.length; // Advance normally for `ins`
            } else if (command === 'cmt') {
                htmlString = htmlString.slice(0, fromPosition) + content + htmlString.slice(fromPosition + fullMatch.length);
                toPosition = fromPosition + content.length;
                valueObj.to = toPosition;
                comments.push(valueObj);
                regex.lastIndex = fromPosition + content.length; // Advance normally for `cmt`
            }
        }
        return { modifiedHTML: htmlString, trackChanges, comments };
      }
      const result = processHTMLString(trackContent);
      console.log('Modified HTML String:\n', result.modifiedHTML);
      console.log('Extracted Array of track Objects:\n', result.trackChanges);
      console.log('Extracted Array of comment Objects:\n', result.comments)
      setTimeout(() => {
        setFileContent(result.modifiedHTML)
        TrackchangesArrayValue = result.trackChanges;
        Comment = result.comments;
        props.trackfileComment(result.comments);
      }, 1000);
    }
  }, [trackContent]);

  useEffect(() => {
    if (editorRef.current) {
      const editor = editorRef.current.querySelector('.cm-editor');
      if (editor) {
        editor.style.fontSize = `${fontSize}px`;
      }
    }
  }, [fontSize]);
  // Create an update listener
  const updateListener = EditorView.updateListener.of((update) => {
    if (update.docChanged) {
      const newContent = update.state.doc.toString();
      setCurrentFile(newContent);
      props.changeData(newContent);
    }
  });

  useEffect(() => {
    const stateValue = async () => {
      if (stateDoc.doc !== undefined) {
        if (stateDoc.doc.lines >= 1) {
          await AQFinder(state.doc);
          //TOCFinder();
        }
      }
    }
    stateValue();
  }, [stateDoc]);

  useEffect(() => {
    // let d = document.querySelector('.buttonTools-area')
    // console.log(props.menuTrigger, d.offsetWidth, 'width')
    // let a = buttonResize.current.offsetWidth;
    // const children = buttonResize.current.children;
    // let total = 0;
    // Array.from(children).forEach((child) => {
    //   total += child.offsetWidth;
    //   if (a > total + 7) {
    //     buttonResize.current.appendChild(child)
    //   } else {
    //     subChildButton.current.appendChild(child)
    //   }
    // });
    // if (a > (buttonElements.length * 34) + 125) {
    //   if (subChildButton.current.lastChild !== undefined) {
    //     buttonResize.current.appendChild(subChildButton.current.lastChild)
    //   }
    // } else {
    //   subChildButton.current.appendChild(buttonResize.current.lastChild)
    // }
    docid = location.search.split('?')[1].split('=')[1];
    let a = JSON.parse(localStorage.getItem(`${docid}_init-getdetails`));
    let activityName = a.ActivtyDetails.ActivityName;
    userProfile = a.ActivtyDetails.userDetails;
    ActivityID = a.ActivtyDetails.ActivityID;
    setActivityName(activityName);
  }, []);

  useEffect(() => {
    props.dispatchCommentValue(Comment);
  }, [Comment]);

  useEffect(() => {
    // setTimeout(() => {
    Comment = props.isCommentData.comment;
    TrackchangesArrayValue = props.isTrackData;
    loadTrackChanges(state);
    // }, 200);
  }, [props.isCommentData, props.isTrackData]);

  useEffect(() => {
    //props.dispatchTrackchanges(TrackchangesArrayValue)
    let dataChange = TrackchangesArrayValue.map((item) => {
      return {
        ...item,
        Username: item.Username?.length === 0 ? userProfile.Name : item.Username,
        role: item.role?.length === 0 ? userProfile.roleName : item.role,
        ActivityID: (item.ActivityID?.length === 0 || item.ActivityID === undefined) ? ActivityID : item.ActivityID,
        Date_Time: item.type === 'insertion'
          ? JSON.parse(item.decoration?.spec.attributes['data-meta']).Date_Time
          : JSON.parse(item.decoration?.spec.metadata).Date_Time,
        cmdtype: 'trackchanges',
        status: ''
      }
    })
    let filter = dataChange.filter((item) => item.type === 'deletion' || item.type === 'insertion')
    props.dispatchTrackchanges(filter)
    props.trackChangesValue(filter)
  }, [TrackchangesArrayValue]);

  useEffect(() => {
    if (props.isFocus) {
      if (props.isFocus.line || props.isFocus.position) {
        focusOnLineError(props.isFocus)
      } else {
        focusOnLine(props?.isFocus)
      }
    }
  }, [props.isFocus])

  useEffect(() => {
    if (props.isDelete) {
      AuthorQueryDelete(props.isDelete)
    }
    if (props.isContent) {
      AuthorQueryUpdate(props.isContent)
    }
  }, [props.isDelete, props.isContent])

  useEffect(() => {
    if (props.isComment.comment !== undefined && props.isComment.comment.length > 0) {
      // console.log(props.isComment, 'iscomment');
      const lastComment = props.isComment.comment[props.isComment.comment.length - 1];
      highlightCommentedText(lastComment);
      if (props.isComment.comment !== undefined) {
        let commentIndex = props.isComment.comment[props.isComment.changeIndex];
        if (commentIndex !== undefined) {
          addComment(view, commentIndex.from, commentIndex.to, commentIndex.selectText, commentIndex.cid, JSON.stringify(commentIndex))
        }
      }
    }
  }, [props.isComment]);

  useEffect(() => {
    if (query.length > 0) {
      props.dispatchAuthorQuery(query)
    }
  }, [query])

  function isYesNoQuestion(sentence) {
    // Define regex patterns for yes/no questions
    const yesNoPatterns = [/^(is|are|do|does|did|can|could|will|would|should|has|have|am|was|were)\b/i];
    // Check if the sentence matches any of the yes/no patterns
    return yesNoPatterns.some(pattern => pattern.test(sentence));
  }

  const AQFinder = async (value) => {
    const doc = value;
    const lineCount = doc.lines;
    const extractedQueries = [];
    const queryRegex = /\\AQ(?:\[[^\]]*\])?\{([^{}]*)\}\{((?:[^{}]|{(?:[^{}]|{[^{}]*})*})*)\}(?:{})?/gis;
    for (let i = 1; i <= lineCount; i++) {
      const lineContent = doc.line(i).text;
      let match;
      while ((match = queryRegex.exec(lineContent)) !== null) {
        extractedQueries.push({
          linenumber: i,
          query: match[2],
          label: match[1],
          wholematch: match[0],
          attachment: [],
          ActivityID: profile.ActivityID,
          Role: profile.role,
          Date: moment(new Date()).format("DD/MM/YYYY")
        });
      }
    }
    if (Object.keys(props.isAuthorQuery).length !== 0) {
      if (extractedQueries.length === props.isAuthorQuery.length) {
        const extractedQueriesList = props.isAuthorQuery.map((itemA) => {
          const a = extractedQueries.find((itemB) => itemB.label === itemA.label);
          return a ? { ...itemA, linenumber: a.linenumber, ...a, } : { ...itemA }
        }
        )
        let checkQuery = extractedQueriesList.map((item) => {
          return {
            ...item,
            aqCheck: isYesNoQuestion(item.query)
          }
        }
        )
        setQuery(checkQuery)
      } else {
        let checkQuery = extractedQueries.map((item) => {
          return {
            ...item,
            aqCheck: isYesNoQuestion(item.query)
          }
        })
        setQuery(checkQuery)
      }
    } else {
      let checkQuery = extractedQueries.map((item) => {
        return {
          ...item,
          aqCheck: isYesNoQuestion(item.query)
        }
      })
      setQuery(checkQuery)
    }
  };



  useEffect(() => {
    if (props.isCommentDelete) {
      removeHighlightedText(props.isCommentDelete);
      if (Object.keys(props.isBlobComment).length !== 0) {
        let deleteBlobComment = props.isBlobComment.filter((item) => item.cid === props.isCommentDelete.cid)
        setTimeout(() => {
          props.dispatchBlobcomment(deleteBlobComment);
        }, 100);
      }
    }
  }, [props.isCommentDelete])

  useEffect(() => {
    if (props.isTrackAction.length !== 0) {
      if (props.isTrackAction.status === "Accept") {
        removeComment(view, props.isTrackAction.list.from, props.isTrackAction.list.to);
      } else {
        let from = props.isTrackAction.list.from;
        let to = props.isTrackAction.list.to;
        let transaction = {};
        if (props.isTrackAction.list.type === 'deletion') {
          removeComment(view, props.isTrackAction.list.from, props.isTrackAction.list.to);
          transaction = view.state.update({
            changes: {
              from: from, // The starting position where you want to insert the text
              to: to,   // The ending position (same as 'from' for insertion)
              insert: props.isTrackAction.list.decoration.spec.text
            }, annotations: Transaction.userEvent.of('deletereject')
          });
        }
        else {
          transaction = view.state.update({
            changes: { from, to }, annotations: Transaction.userEvent.of('reject')
          });
        }
        view.dispatch(transaction);
        //view.dispatch({ effects: clearCommentEffect.of({ from, to }) });
      }

      // const currentState = view.state;
      // const transaction = currentState.update({
      //   changes: {
      //     from: props.isTrackAction.from,
      //     to: props.isTrackAction.to,
      //     insert:props.isTrackAction.text
      //   }
      // });

      // view.dispatch(transaction);
    }
  }, [props.isTrackAction])

  // useEffect(() => {
  //   const observer = new ResizeObserver(entries => {
  //     //setwidth(entries[0].contentRect.width)
  //     let buttonareaWidth = entries[0].contentRect.width;
  //     const children = buttonResize.current.children;
  //     const buttonElements = Array.from(children).filter(child => child.tagName === 'BUTTON');
  //     console.log(buttonareaWidth, (buttonElements.length * 34) + 125, 'buttonareaWidth')
  //     if (buttonareaWidth > (buttonElements.length * 34) + 125) {
  //       buttonResize.current.appendChild(subChildButton.current.lastChild)
  //     } else {
  //       subChildButton.current.appendChild(buttonResize.current.lastChild)
  //     }
  //   })
  //   observer.observe(buttonResize.current)
  //   return () => buttonResize.current && observer.unobserve(buttonResize.current)
  // }, [])

  function addComment(view, from, to, comment, cid, commentmeta) {
    if (view) {
      view.dispatch({
        effects: commentEffect.of({ from, to, comment, cid, commentmeta })
      });
      view.focus();
    }
  }

  // Function to highlight the selected text 
  // const highlightCommentedText = useCallback((comment) => {
  //   console.log(comment,'comment');
  //   if (comment.length > 0) {
  //     if (comment && view && view.state) {
  //       const selection = view.state.selection;
  //       const doc = view.state.doc;
  //       const selectionFrom = selection.ranges[selection.ranges.length - 1].from;
  //       const selectionTo = selection.ranges[selection.ranges.length - 1].to;
  //       if (doc && selection && selectionFrom !== undefined && selectionTo !== undefined) {
  //         const cid = comment[comment.length - 1].cid;
  //         if (cid !== prevCid.current) {
  //           const selectedText = doc.sliceString(selectionFrom, selectionTo);
  //           const commentText = `${selectedText}`;
  //           let selection1 = view.state.selection;
  //           if (comment && !selection1.empty) {
  //             let { from, to } = selection1.main;
  //             addComment(view, from, to, commentText, cid, comment[comment.length - 1])
  //           }
  //           setComments((prevComments) => ({
  //             ...prevComments,
  //             [cid]: [...(prevComments[cid] || []), comment[comment.length - 1]]
  //           }));
  //           // Update the previous cid
  //           prevCid.current = cid;
  //         }
  //       }
  //     }
  //   }

  // }, [view, setComments, prevCid]);

  const highlightCommentedText = useCallback((comments) => {
    // console.log(comments, 'comments');

    if (comments) {
      // if (view && view.state) {
      //   comments.forEach((comment) => {
      const { from, to, cid } = comments;

      // Only add the comment if the cid is different from the previous one processed
      if (cid !== prevCid.current) {
        // Use the selectText directly from the comment
        const commentText = comments.selectText;

        // Call addComment directly with the comment data
        addComment(view, from, to, commentText, cid, comments);

        // Update the state with the new comment
        setComments((prevComments) => ({
          ...prevComments,
          [cid]: [...(prevComments[cid] || []), comments]
        }));
        // Update the previous cid
        prevCid.current = cid;
      }
      // });
      // }
    }
  }, [view, setComments, prevCid]);

  // Function to remove highlighted text
  // const removeHighlightedText = useCallback((cid) => {debugger
  //   let comments = props.isComment?.comment;
  //   if (comments != null && cid.cid != undefined) {
  //     let comment = comments.filter(item => item.cid == cid.cid);
  //      if (comment.length > 0) {
  //       let { from, to } = comment[0];
  //       removeComment(view, from, to);
  //     }
  //   }
  // }, [view]);

  const removeHighlightedText = useCallback((cid) => {
    if (comments != null && cid.cid != undefined) {
      let { from, to } = cid;
      removeComment(view, from, to);
      setComments((prevComments) => {
        const commentArray = prevComments[cid.cid] || [];
        return {
          ...prevComments,
          [cid.cid]: commentArray.filter(comment => comment.from !== from || comment.to !== to)
        };
      });
    }
  }, [view, comments]);


  function removeComment(view, from, to) {
    view.dispatch({ effects: clearCommentEffect.of({ from, to }) });
    view.focus();
    // Update the track changes data
    saveTrackChanges(view.state);
  }

  const focusOnLine = (item) => {
    // Get the position of the start of the line
    if (item.linenumber !== undefined) {
      const pos = view.state.doc.line(item.linenumber).from;
      let from = item.from;
      let to = item.to;
      if (from || to) {
        view.dispatch({
          selection: { anchor: from, head: to },
          scrollIntoView: true
        });
      } else {
        view.dispatch({
          effects: EditorView.scrollIntoView(pos, { y: 'center' }),
          selection: { anchor: pos }
        });
      }
    } else if (item.linenumber === undefined) {
      let from = item.from;
      let to = item.to;
      view.dispatch({
        selection: { anchor: from, head: to },
        scrollIntoView: true
      });
    }
    else {
      const pos = view.state.doc.line(item).from;
      view.dispatch({
        effects: EditorView.scrollIntoView(pos, { y: 'center' }),
        selection: { anchor: pos }
      });
    }
    view.focus();
    // Update track changes data for the focused line
    saveTrackChanges(view.state);
  };

  const focusOnLineError = (item) => {
    // Get the position of the start of the line
    const pos = view.state.doc.line(item.line === undefined ? item.position : item.line).from;
    view.dispatch({
      effects: EditorView.scrollIntoView(pos, { y: 'center' }),
      selection: { anchor: pos }
    });
    view.focus();
    // Update track changes data for the focused line
    saveTrackChanges(view.state);
  };

  const AuthorQueryDelete = (QueryDelete) => {
    if (!view) return;
    const { linenumber, Content: wholeMatch } = QueryDelete;
    // Adjusting to 1-based indexing for line numbers
    if (linenumber <= 0 || linenumber > view.state.doc.lines) {
      // console.error(`Invalid line number ${linenumber}`);
      return;
    }
    const line = view.state.doc.line(linenumber);
    const lineContent = line.text;
    if (lineContent !== undefined) {
      const updatedLineContent = lineContent.replace(wholeMatch, '');
      const transaction = view.state.update({
        changes: {
          from: line.from,
          to: line.to,
          insert: updatedLineContent
        }
      });
      view.dispatch(transaction);
    }
  };

  const AuthorQueryUpdate = (QueryDetails) => {
    if (!view) return;
    const lineNumber = QueryDetails.linenumber;
    const editedContent = QueryDetails.changecontent;
    if (lineNumber <= 0 || lineNumber > view.state.doc.lines) {
      return;
    }
    const line = view.state.doc.line(lineNumber);
    const lineContent = line.text;
    if (lineContent !== undefined) {
      const match = QueryDetails.oldcontent.match(/\\AQ(?:\[[^\]]*\])?\{[^{}]*\}\{((?:[^{}]|{(?:[^{}]|{[^{}]*})*})*)\}(?:{})?/);
      const wholeMatch = match ? match[1] : "";
      const updatedLineContent = lineContent.replace(wholeMatch, editedContent);
      const tr = view.state.update({
        changes: {
          from: line.from,
          to: line.to,
          insert: updatedLineContent
        }
      });
      view.dispatch(tr);
    } else {
      console.error(`Line content is undefined for line number: ${lineNumber}`);
    }
  };

  const commentAction = () => {
    props.dispatchCommentSidebar({ selectValue: selectedText, showSidebar: 'rightbar', commentValue: 'comment' })
  }

  const trackToggle = (val) => {
    setIsTrack(val)
  }

  // Handle symbol data received from ToolbarItems
  const handleSymbolData = useCallback((symbol) => {
    console.log('Received symbol in CodeMirrorEditor:', symbol);

    if (view) {
      const { state } = view;
      const { selection } = state;

      if (selection.main.from !== null) {
        const cursorPos = selection.main.to;
        const symbolText = symbol; // Assuming symbol is the text you want to insert
        const symbolLength = symbolText.length;
        const newPos = cursorPos + symbolLength;

        view.dispatch({
          changes: {
            from: cursorPos,
            to: cursorPos,
            insert: symbolText,
          },
          selection: {
            anchor: newPos,
            head: newPos,
          },
        });
      }
    }
  }, [view]);

  const handleButton = async (type) => {
   setShowprogress(true)
    const docid = profile.docid;
    switch (type) {
      case "styleediting":
        setProgressDetails(true);
        setProgressDetails("Please wait Style Editing is progress.")
        let data = { 'texfilename': currentFile, 'texcontent': fileContent, "s5filename": " _S2950338824000081-20240617_080312_S5.xml ", "s5content": "UTF-8 base64 encoded cnt", "inifilename": " ELS_user_preedit-mapping.ini", "inicontent": "UTF-8 base64 encoded cnt", "docid": docid };
        const result = await styleEditing(data);
        props.styleEditingError(result);
        setShowprogress(false)
        break;
      case "referanceordering":
        setProgressDetails(false);
        setProgressDetails("Please wait Reference Ordering is progress -")
        const runningApi = (val) => {
          setApiStatusRunning(val)
        }
        let refData = { 'texfilename': currentFile, 'texcontent': fileContent };
        const refResult = await referanceOrdering(refData, runningApi);
        props.refrenceStrucutre(refResult);
        setShowprogress(false)
        break;
      case "clearcachedfiles":
        if (docid !== undefined || docid !== null) {
          setProgressDetails("Please wait clear cache files is progress.")
          const clearCache = await clearCacheFiles(docid);
          setShowprogress(false)
          if (clearCache.isDeleted) {
            toastSuccess(`${clearCache.message}`);
          } else {
            toastError(`${clearCache.details}`);
          }
        } else {
          toastError('Docid is missing.');
        }
        break;
      case "cleanup":
        setProgressDetails(false);
        setProgressDetails("Please wait clean up is progress")
        let cleanData = { 'texfilename': currentFile, 'texcontent': fileContent, "docid": docid };
        const cleanResult = await CleanupEditor(cleanData);
        props.MacroCleanUp(cleanResult);
        setShowprogress(false)
        break;
      default:
        console.log(`Unhandled button type: ${type}`);
        break;
    }
  }

  useEffect(() => {
    // debugger
    if (props.isPDFText) {
      compareLineText(props.isPDFText);
      console.log('Found line number:', props.isPDFText);
    }
  }, [props.isPDFText, numberedContent]);

  useEffect(() => {
    if (!fileContent) {
      console.log("fileContent is empty or undefined", fileContent);
      return;
    }

    // Remove citations like \cite{min2019survey}
    let cleanedContent = fileContent.replace(/\\cite\{.*?\}/g, '');

    // Remove specific LaTeX commands and extract the text inside the brackets
    const latexCommands = [
      'part', 'chapter', 'section', 'subsection',
      'subsubsection', 'paragraph', 'subparagraph'
    ];

    // Build a regex to match these LaTeX commands
    const commandRegex = new RegExp(`\\\\(${latexCommands.join('|')})\\{(.*?)\\}`, 'g');

    // Replace the LaTeX commands with just the text inside the braces
    cleanedContent = cleanedContent.replace(commandRegex, '$2');

    // Split the content into lines
    const lines = cleanedContent.split('\n');
    console.log("Total lines:", lines.length);

    // Add line numbers and join the lines
    const numberedLines = lines.map((line, index) => `Line ${index + 1}: ${line.trim()}`);
    const newContent = numberedLines.join('\n');

    // Store in state
    setNumberedContent(newContent);

    // Log the result
    console.log("Numbered Content:\n", newContent);

  }, [fileContent]);



  const compareLineText = (searchText) => {
    // debugger
    console.log('searchText', searchText)
    const lines = numberedContent.split('\n');
    for (const line of lines) {
      if (line.includes(searchText)) {
        const TextlineNumber = parseInt(line.split(':')[0].trim().split(' ')[1]);
        console.log(`Found "${searchText}" at line number: ${TextlineNumber}`);
        setTextLineNumber(TextlineNumber);
        // focusOnLine(TextlineNumber);
        console.log(TextlineNumber, 'TextlineNumber')
        return TextlineNumber;
      }
    }
    console.log(`"${searchText}" not found in the content`);
    return 0;
  };

  useEffect(() => {
    if (TextlineNumber && view && typeof TextlineNumber === 'number') {
      console.log(TextlineNumber, 'TextlineNumber');
      if (TextlineNumber !== 1) {
        focusOnLineError({ line: TextlineNumber });
      }
    }
  }, [TextlineNumber, view]);


  return (
    <>
      <div className='arrowCenter'>
        {/* <button onClick={editorToPdfText} className='div-border' title="Go to code location in PDF"><FaArrowRight /></button> */}
        {/* <button className='div-border' title="Go to code location in PDF"><FaArrowLeft /></button> */}
      </div>
      <div className="latex-editor">
        <Modal size="md" show={showProgress} aria-labelledby="contained-modal-title-vcenter" centered className="confirmation-popup">
          <Modal.Body>
            <div>
              <p>{progressDetails} {ApiStatusRunning.length !== 0 ? <span>{ApiStatusRunning}</span> : ''} </p>
              <span className="loader"></span>
            </div>
          </Modal.Body>
        </Modal>
        <div className='editor-btns-group'>
          <div className='editor-btn-inner'>
            <div style={{ display: "flex" }}>
              <div className='buttonTools-area-size'>
                <div ref={buttonResize} className='buttonTools-area'>
                  <ToolbarItems
                    state={state}
                    view={view}
                    overflowed={false}
                    languageName={lang}
                    visual={visual}
                    disabled={showCommentButton}
                    handleSymbolData={handleSymbolData} // Pass function down to ToolbarItems
                  />
                  <button disabled={showCommentButton} onClick={commentAction} className='div-border' title="Comment"><FaRegComment /></button>
                </div>
                {profile?.role !== "Author" && (
                  <div className='buttonTool-child'>
                    <button className='div-border'><FaEllipsisH /></button>
                    <div className='buttonTool-child-inner' ref={subChildButton}>
                      <button className='title-icon-btn' title="Cleanup" onClick={() => handleButton('cleanup')}>Cleanup</button>
                      <button className='title-icon-btn' title="Pre Structuring">Pre Structuring</button>
                      {ActivityName === "L1" ? <><button className='title-icon-btn' title="Style Editing" onClick={() => handleButton('styleediting')}>Style Editing</button>
                        <button className='title-icon-btn' title="Referance Ordering" onClick={() => handleButton('referanceordering')}>Reference Ordering</button></> : ''}
                      <button className='title-icon-btn' title="XML Conversion">XML Conversion</button>
                      <button className='title-icon-btn' title="iAutopage" onClick={() => handleButton('iAutopage')}>iAutopage</button>
                      <button className='title-icon-btn' title="Compiler">Compiler</button>
                      <button className='title-icon-btn' title="iprintQC">iprintQC</button>
                      <button className='title-icon-btn' title="Clear cached files" onClick={() => handleButton('clearcachedfiles')}>Clear cached files</button>
                    </div>
                  </div>
                )}
              </div>
              {/* <div className='switch_track div-border'>
              <Form>
                <Form.Check
                  type="switch"
                  id="track_changes"
                  label="Track Changes"
                  onChange={(e) => trackToggle(e)}
                  disabled="true"
                />
              </Form>
            </div> */}
            </div>
          </div>
          <div className='editor-btn-inner'>
            <Button className="switchbutton" onClick={() => props.layoutview('pdfonly')}> <FaRegFilePdf /> Switch to pdf</Button>
          </div>
        </div>
        <div ref={editorRef} />
        {contextMenu.visible && (
          <div className='quickbar-toolbar'
            style={{
              top: contextMenu.y,
              left: contextMenu.x,
            }}
          >
            <ToolbarItems
              state={state}
              view={view}
              overflowed={false}
              languageName={lang}
              visual={visual}
              disabled={showCommentButton}
              handleSymbolData={handleSymbolData} // Pass function down to ToolbarItems
            />
            <button disabled={showCommentButton} onClick={commentAction} className='div-border' title="Comment"><FaRegComment /></button>
          </div>
        )}
      </div>
    </>
  );
};

const CodeMirrorStateContext = createContext < EditorState | undefined > (undefined)

export const useCodeMirrorStateContext = () => {
  const context = useContext(CodeMirrorStateContext)

  if (!context) {
    return null;
  }

  return context
}

const CodeMirrorViewContext = createContext < EditorView | undefined > (undefined)

export const CodeMirrorViewContextProvider = CodeMirrorViewContext.Provider

export const useCodeMirrorViewContext = () => {
  const context = useContext(CodeMirrorViewContext)

  if (!context) {
    return null;
  }

  return context
}

const mapStateToProps = (state) => {
  return {
    isFocus: state.RightSidebar.Focus,
    isContent: state.RightSidebar.AQchange,
    isDelete: state.RightSidebar.AQdelete,
    isComment: state.RightSidebar.Comments,
    isCommentDelete: state.RightSidebar.DELETE_COMMENT,
    isAuthorQuery: state.RightSidebar.AuthorQueryData,
    isBlobComment: state.RightSidebar.blobCommentValue,
    isTrackAction: state.RightSidebar.TrackActionData,
    isTrackData: state.RightSidebar.TrackChangedData,
    isCommentData: state.RightSidebar.Comments,
    isPDFText: state.RightSidebar.PDFText,
    isDocDetails: state.LayoutReducer.DocDetails
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatchView: (docvalue) => {
      dispatchView(dispatch, docvalue)
    },
    dispatchAuthorQuery: (QueryValue) => {
      dispatchAuthorQuery(dispatch, QueryValue)
    },
    dispatchLayoutValue: (Tocdata) => {
      dispatchLayoutValue(dispatch, Tocdata)
    },
    dispatchCommentSidebar: (selectTextValue) => {
      dispatchCommentSidebar(dispatch, selectTextValue)
    },
    dispatchCommentValue: (value) => {
      dispatchCommentValue(dispatch, value)
    },
    dispatchBlobcomment: (blobComment) => {
      dispatchBlobcomment(dispatch, blobComment)
    },
    dispatchTrackchanges: (trackvalue) => {
      dispatchTrackchanges(dispatch, trackvalue)
    },
    dispatchTextFromEditor: (editorText) => {
      dispatchTextFromEditor(dispatch, editorText)
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CodeMirrorEditor);
